
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    steps: {
      type: Array,
      required: true
    },
    activeStep: {
      type: Number,
      required: true
    }
  },
  computed: {
    stepCount(): number {
      return this.steps.length || 0;
    }
  }
});
