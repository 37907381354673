export default function usePaymentOptions(): any {
  function getPaymentOptionHeader(type: number | string): string {
    switch (type) {
      case 1:
      case "Flat":
        return "";
      case 2:
      case "Deferred":
        return "Defer Payments Until After School";
      case 3:
      case "Interest":
        return "Interest Only During School";
      case 4:
      case "Regular":
        return "Immediately Start Payments";
      default:
        return "";
    }
  }
  function repaymentTermEnum(repaymentTermId: number): number {
    switch (repaymentTermId) {
      case 1:
        return 5;
      case 2:
        return 7;
      case 3:
        return 10;
      case 4:
        return 15;
      case 5:
        return 20;
      case 6:
        return 25;
      default:
        return 0;
    }
  }
  function getRateTypeName(rateTypeId: number): string {
    switch (rateTypeId) {
      case 1:
        return "Fixed";
      case 2:
        return "Variable";
      default:
        return "";
    }
  }
  function getRateOptionHeader(rateTypeId: number): string {
    switch (rateTypeId) {
      case 1:
        return "Fixed Interest Rate";
      case 2:
        return "Variable Interest Rate";
      default:
        return "";
    }
  }
  function getRateDescription(rateTypeId: number): string {
    switch (rateTypeId) {
      case 1:
        return "Your interest rate will remain the same for the life of your line. The fixed rate option offers a shorter repayment period than the variable rate option, which could result in a higher monthly payment. The payment estimates below are based on your initial request for funds, using a daily simple interest calculation, which includes 4- and one-half years of enrollment, a 6-month grace period, and interest capitalization when you enter repayment.";
      case 2:
        return "Your interest rate and monthly payments rise and fall based on the Prime index. The variable rate option does allow for a longer repayment period than the fixed rate option, which could result in a lower monthly payment. The payment estimates below are based on your initial request for funds, your initial interest rate, and uses a daily simple interest calculation, which includes 4- and one-half years of enrollment, a 6-month grace period, and interest capitalization when you enter repayment.";
      default:
        return "";
    }
  }

  function getDgRateDescription(rateTypeId: number): string {
    switch (rateTypeId) {
      case 1:
        return "Your interest rate will remain the same for the life of your line. The fixed rate option offers a shorter repayment period than the variable rate option, which could result in a higher monthly payment. The payment estimates below are based on your initial request for funds, using a daily simple interest calculation, which includes 2 years of enrollment, a 6-month grace period, and interest capitalization when you enter repayment.";
      case 2:
        return "Your interest rate and monthly payments rise and fall based on the Prime index. The variable rate option offers a longer repayment period than the fixed rate option, which could result in a lower monthly payment. The payment estimates below are based on your initial request for funds, using a daily simple interest calculation, which includes 2 years of enrollment, a 6-month grace period, and interest capitalization when you enter repayment.";
      default:
        return "";
    }
  }

  function getRepaymentTermHeader(repaymentTerm: number): string {
    switch (repaymentTerm) {
      case 20:
        return "20-25";
      default:
        return `${repaymentTerm}`;
    }
  }

  function getOrdinal(n: number) {
    let ord = "th";

    if (n % 10 == 1 && n % 100 != 11) {
      ord = "st";
    } else if (n % 10 == 2 && n % 100 != 12) {
      ord = "nd";
    } else if (n % 10 == 3 && n % 100 != 13) {
      ord = "rd";
    }

    return n + ord;
  }

  function formatRatePercentages(n: number, decimalPlaces: number) {
    return `${n.toLocaleString(undefined, { minimumFractionDigits: decimalPlaces })}%`;
  }

  function formatStartOfQuarter(date: any) {
    if (date) {
      const dateObj = new Date(date);
      const day = dateObj.getDate();
      const formattedDay = getOrdinal(day);
      const month = dateObj.toLocaleString("en-us", { month: "long" });
      const year = dateObj.toLocaleString("en-us", { year: "numeric" });

      return `${month} ${formattedDay}, ${year}`;
    }
  }
  return {
    getRateTypeName,
    getPaymentOptionHeader,
    repaymentTermEnum,
    getRateOptionHeader,
    getRateDescription,
    getRepaymentTermHeader,
    getDgRateDescription,
    getOrdinal,
    formatRatePercentages,
    formatStartOfQuarter,
  };
}
