
import { defineComponent } from "vue";
import useFormatter from "../../use/formatter";
import BaseFormField from "../shared/BaseFormField.vue";
import Icon from "../shared/Icon.vue";
import { mapActions, mapState } from "vuex";
import { School } from "@/models/school";
import { $api } from "@/services/api";
import { FormSchema } from "@/models/form";

export default defineComponent({
  setup() {
    const { formatNumber } = useFormatter();
    return { formatNumber };
  },
  props: {
    application: {
      type: Object,
      required: true,
    },
    term: {
      type: String,
      required: true,
    },
    info: {
      type: Object,
    },
    isCurrentSubStep: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
    },
  },
  components: { BaseFormField, Icon },
  data: () => ({
    selectedSchool: {} as School,
    tuitionType: "in-state",
    gettingData: true,
    funding: {
      scholarshipsAndGrants: 0 as undefined | number,
      loans: 0 as undefined | number,
      savings: 0 as undefined | number,
    } as any,
    schema: {
      scholarshipsAndGrants: {
        name: "scholarshipsAndGrants",
        type: "money",
        hideLabel: true,
        default: 0,
        required: false,
      },
      loans: {
        name: "loans",
        type: "money",
        hideLabel: true,
        default: 0,
        required: false,
      },
      savings: {
        name: "savings",
        type: "money",
        hideLabel: true,
        default: 0,
        required: false,
      },
    } as FormSchema,
  }),
  watch: {
    tuitionCosts(newValue, oldValue) {
      console.log("tuitionCosts", "old", oldValue, "new", newValue);
      if (isNaN(newValue.inState)) {
        this.gettingData = true;
      } else {
        this.gettingData = false;
      }
    },
  },
  computed: {
    ...mapState(["appData", "schools"]),
    school(): any {
      return this.appData?.application?.schoolConfiguration?.schoolName;
    },
    combinedTerms(): boolean {
      return (this.appData?.application?.schoolConfiguration?.academicTerm || "").includes("-")
    },
    tuitionCosts(): any {
      let tuitionCosts = {} as any;
      this.setSchoolInfo();

      tuitionCosts.inState =
        this.combinedTerms
          ? this.selectedSchool?.costOfAttendanceInState
          : this.selectedSchool?.costOfAttendanceInState / 2;

      tuitionCosts.outOfState =
        this.combinedTerms
          ? this.selectedSchool?.costOfAttendanceOutOfState
          : this.selectedSchool?.costOfAttendanceOutOfState / 2;
      return tuitionCosts;
    },
    tuition(): number {
      return this.tuitionType === "in-state"
        ? !isNaN(this.tuitionCosts.inState)
          ? this.tuitionCosts.inState
          : 0
        : !isNaN(this.tuitionCosts.outOfState)
        ? this.tuitionCosts.inState
        : 0;
    },
    sum(): number {
      return (
        Object.keys(this.funding).reduce((previous, key) => {
          if (!this.funding[key]) {
            return previous;
          } else {
            return previous + parseFloat(this.funding[key]) || 0;
          }
        }, 0) || 0
      );
    },
    difference(): number {
      return this.tuition - this.sum;
    },
  },
  methods: {
    ...mapActions(["setSchools"]),
    async setSchoolInfo() {
      if (this.schools.length <= 0) {
        const res = await $api.school.getSchoolsByProgram(this.appData?.application?.programTypeId, this.appData?.application?.productTypeId, this.appData?.application?.lenderId);
        this.setSchools(res.schools);
      }

      const school = this.schools.find(
        (s: School) =>
          s.id === this.appData?.application?.schoolConfiguration?.schoolId ||
          s.id === this.appData?.application?.schoolId ||
          s.schoolName === this.appData?.application.schoolConfiguration?.schoolName
      );
      this.selectedSchool = school || {};
    },
    useThisAmount() {
      this.$emit("useThisAmount", Math.round(this.difference));
    },
    close() {
      this.$emit("close", true);
    },
  },
});
