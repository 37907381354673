import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e93ae52"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "footer-btns"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    class: "logout-modal",
    closeBtn: _ctx.allowClose
  }, {
    header: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.confirmed ? "Logging Out" : "Log Out"), 1)
    ]),
    footer: _withCtx(() => [
      (!_ctx.confirmed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["prevent"]))
            }, " Cancel "),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.confirmLogout && _ctx.confirmLogout(...args)), ["prevent"]))
            }, " Logout ")
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.confirmed
          ? "Logging out of the SLC Applicant Portal..."
          : "Are you sure you want to log out?"), 1)
    ]),
    _: 1
  }, 8, ["closeBtn"]))
}