import { $api } from "../services/api";
import store from "@/store";
import { useRoute } from "vue-router";
import useFormatter from "@/use/formatter";

const { formattedDateFormat} = useFormatter();

export default function useApplications() {
  async function getUserApplications(unifiId: string) {
    const response = await $api.applications.getUserApplications(unifiId);
    if (response.error || !response.applications) {
      return [];
    } else {
      return response.applications;
    }
    return;
  }

  async function getUserApplication(unifiId: string, appId: string) {
    const response = await $api.applications.getUserApplication(unifiId, appId);
    if (response.error || !response.application) {
      return [];
    } else {
      return response.application;
    }
    return;
  }
  async function getLenderConfigurationByReferenceId(unifiId: string, referenceId: string) {
    const response = await $api.applications.getUserApplicationByRefId(unifiId, referenceId, 0);
    
    const lenderConfig = await $api.configuration.getLenderConfiguration(
      response.appData.application.programTypeId,
			response.appData.application.productTypeId,
			response.appData.application.lenderId
    );

    if (response.error || !response.appData.application) {
      return [];
    } else {
      return lenderConfig;
    }
    return;
  }
  async function getUserApplicationApplicant(unifiId: string, appId: string) {
    const response = await $api.applications.getUserApplication(unifiId, appId);
    if (response.error || !response.applicants) {
      return [];
    } else {
      return response.applicants;
    }
    return;
  }

  async function rehydrateStore() {
    const route = useRoute();
    const oidcUser = store.getters['oidcStore/oidcUser'];
    let applicationsResponse;

    const applicationResponse = await $api.applications.getUserApplicationByRefId(oidcUser.sub, route.params?.referenceId as string, 0);
    const applicationDocumentsResponse = await $api.documents.getApplicationDocuments(applicationResponse?.appData?.application?.id);
    const applicantDocumentsResponse = await $api.documents.getApplicantDocuments(applicationResponse?.currentApplicant[0].id);
    const applicationStipsResponse = await $api.applications.getApplicationStips(applicationResponse?.appData?.application?.id);
    const generatedDocuments = await $api.documents.getGeneratedDocumentsByRefId(route.params?.referenceId as string);
    
    if (applicationResponse?.appData) {
      const activeApplication = applicationResponse?.appData?.application;
      const applicationTypeId = applicationResponse?.appData?.application?.metadata?.withCoApplicants === true ? 2 : 1;
      const incomeLimit = applicationTypeId === 1 ? 'BorrowerMinIncomeCondApproved' : 'CoBorrowerMinIncomeCondApproved';
      const limitTypeId =  applicationTypeId === 1 ? 9 : 28;      
      const dateToCheck = applicationResponse?.appData?.application?.submittedAtUtc ? formattedDateFormat(applicationResponse?.appData?.application?.submittedAtUtc, "YYYY-MM-DD") : applicationResponse?.appData?.application?.submittedAtUtc;
      if (dateToCheck !== null) {
        const incomeLimitExists = await $api.programpricing.incomeLimitCheck(activeApplication?.lenderId as number, activeApplication?.programTypeId, activeApplication?.productTypeId, limitTypeId, dateToCheck as Date);
        store.dispatch("checklistModule/setShowIncomeVerification", incomeLimitExists);
      }
    }
   
    const appData = {
      applicants: applicationResponse?.appData?.applicants,
      application: applicationResponse?.appData?.application,
      currentApplicant: applicationResponse?.currentApplicant[0],
      applicationStips: applicationStipsResponse?.results || [],
      applicantDocuments: applicantDocumentsResponse?.error ? false : applicantDocumentsResponse || [],
      applicationDocuments: applicationDocumentsResponse?.error ? false : applicationDocumentsResponse || [],
      generatedDocuments: generatedDocuments || []
    }

    store.dispatch('setApplications', applicationsResponse);
    store.dispatch('setAppData', appData);
    console.log('appData', store.state.appData);
  }
  return {
    getUserApplications,
    getUserApplication,
    getUserApplicationApplicant,
    getLenderConfigurationByReferenceId,
    rehydrateStore
  };
}
