
import { defineComponent, reactive, ref, watch } from "vue";
import useValidation from "@/use/validation";
import { School } from "@/models";
import { FormSchema, Option } from "@/models/form";
import { useStore, mapGetters } from "vuex";
import Loader from "@/components/shared/Loader.vue";
import BaseFormField from "@/components/shared/BaseFormField.vue";
import DialogueModal from "../modals/DialogueModal.vue";
export default defineComponent({
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  emits: ["complete", "close", "goBack", "getLoanDetails"],
  components: { Loader, BaseFormField, DialogueModal },
  setup(props, { emit }) {
    const { validate, validateForm } = useValidation();
    const store = useStore();
    const loading = ref<boolean>(true);
    const editSchool = ref<boolean>(false);
    const validSchoolInfo = ref<boolean>(false);
    const changeSchoolLoading = ref<boolean>(false);
    const showDialogueModal = ref<boolean>(false);
    const validSchool = ref<boolean>(false);
    const changeSchoolSchema = {
      school: {
        type: "autocomplete",
        label: "School Name",
        autocompleteProperty: "schoolName",
        required: true,
      },
    } as FormSchema;

    let newSchool = reactive({
      school: {} as School,
      state: {} as any,
    }) as any;

    // Methods
    async function getData() {
      loading.value = true;
      await store.dispatch("fundsModule/fetchSchoolsAndStates");
    }

    function openDialogueModal() {
      showDialogueModal.value = true;
    }

    function handleDialogueModal() {
      emit("close");
    }

    return {
      validate,
      validateForm,
      store,
      loading,
      editSchool,
      validSchoolInfo,
      changeSchoolLoading,
      changeSchoolSchema,
      newSchool,
      getData,
      showDialogueModal,
      openDialogueModal,
      validSchool,
      handleDialogueModal,
    };
  },
  computed: {
    ...mapGetters("fundsModule", ["GetErrorMessage", "School"]),
    schools(): any[] {
      const schools = this.store.state.fundsModule.schools;
      return schools.sort((a: any, b: any) => {
        if (a.schoolName < b.schoolName) {
          return -1;
        }
        if (a.schoolName > b.schoolName) {
          return 1;
        }
        return 0;
      });
    },
    SchoolName(): string {
      if (this.store.state.fundsModule.schools && this.store.state.fundsModule.school?.schoolId) {
        const { schoolId } = this.store.state.fundsModule.school;
        const { schoolName } = this.store.state.fundsModule.schools.find(
          (s: any) => s.id == schoolId,
        );
        return schoolName;
      }

      return "";
    },
    SchoolStateName(): string {
      if (this.store.state.fundsModule.states && this.store.state.fundsModule.school?.schoolId) {
        const { schoolId } = this.store.state.fundsModule.school;
        const { state } = this.store.state.fundsModule.schools.find((s: any) => s.id == schoolId);
        const { name } = this.store.state.fundsModule.states.find(
          (s: any) => s.abbreviation === state,
        );

        return name;
      }

      return "";
    },
  },
  methods: {
    cancelButton() {
      if (this.editSchool && this?.School?.schoolCode && this?.School?.schoolBranch) {
        this.changeSchoolSchema.school.required = false;
        // User wants to go back to confirmation
        this.editSchool = false;
        this.newSchool = {};
      } else {
        // User wants to close out of modal
        this.openDialogueModal();
      }
    },
    async changeSchool() {
      this.editSchool = true;
      this.changeSchoolLoading = true;
      this.loading = true;
      this.newSchool = {
        state: {},
        school: {},
      };

      await this.getData();
      this.loading = false;
      this.changeSchoolLoading = false;
    },
    findSchool(schoolDOEId: string, schoolBranchDOEId: string) {
      const findSchool = this.store.state.fundsModule.schools.find(
        (s: any) => s.schoolDOEId === schoolDOEId && s.schoolBranchDOEId === schoolBranchDOEId,
      );
      if (findSchool) {
        const school: School = {
          schoolId: findSchool.id,
          schoolCode: findSchool.schoolDOEId,
          schoolBranch: findSchool.schoolBranchDOEId,
          schoolLevelTypeId: findSchool.schoolTypeId,
          schoolName: findSchool.schoolName,
        };

        //Update the Funds Module State
        this.store.commit("fundsModule/setSchool", school);
        this.editSchool = false;
      }
    },
    getSchool(school: any) {
      if (this.editSchool) {
        this.validSchool = this.newSchool?.school?.id > 0;
        if (this.newSchool?.school?.id > 0) {
          this.changeSchoolSchema.school.valid = true;
          this.changeSchoolSchema.school.errors = [];
        } else {
          this.changeSchoolSchema.school.valid = false;
        }
      }
    },
    confirmSchool() {
      if (this.editSchool) {
        this.changeSchoolSchema.school.required = true;
        const changeSchoolFormValid = this.validateForm(this.changeSchoolSchema, {
          school: this.newSchool?.school,
        });
        if (changeSchoolFormValid) {
          // Set the new school value
          const school: School = {
            schoolBranch: this.newSchool.school.schoolBranchDOEId,
            schoolId: this.newSchool.school.id,
            schoolCode: this.newSchool.school.schoolDOEId,
            schoolLevelTypeId: this.newSchool.school.schoolTypeId,
            schoolName: this.newSchool.school.schoolName,
          };

          //Update the Funds Module State
          this.store.commit("fundsModule/setSchool", school);

          this.editSchool = false;
          this.newSchool = {
            state: {},
            school: {},
          };
        }
      } else {
        // Do not allow them to proceed unless they select a school
        const valid = this.validateForm(this.changeSchoolSchema, {
          school: this.School,
        });
        if (valid) {
          this.$emit("complete", { step: 2 });
        }
      }
    },
  },
  async mounted() {
    this.loading = true;
    this.changeSchoolLoading = true;
    this.editSchool = true;
    await this.getData();

    // Check if they passed the loan..
    // There needs to be draws...
    if (this.loan && this.loan.draws && this.loan.draws.length > 0) {
      const draw = this.loan.draws[this.loan.draws.length - 1];
      if (draw?.schoolBranch && draw?.schoolCode && draw?.schoolLevelTypeId) {
        this.validSchoolInfo = true;
        this.findSchool(draw?.schoolCode, draw?.schoolBranch);
      }
    } else if (
      this.School?.schoolBranch &&
      this.School?.schoolCode &&
      this.School?.schoolLevelTypeId
    ) {
      this.validSchoolInfo = true;
      this.findSchool(this.School?.schoolCode, this.School?.schoolBranch);
    }

    this.loading = false;
    this.changeSchoolLoading = false;
  },
});
