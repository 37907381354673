
import { defineComponent } from "vue";
import { mapActions, mapState } from "vuex";
import useSignOut from "../../use/signout";
import Modal from "../shared/Modal.vue";

export default defineComponent({
  setup() {
    const { signOut } = useSignOut();
    return { signOut };
  },
  components: {
    Modal,
  },
  data: () => ({
    allowClose: true,
    confirmed: undefined as any,
  }),
  computed: {
    ...mapState(["lenderData"]),
  },
  methods: {
    ...mapActions("oidcStore", ["removeOidcUser", "signOutOidcSilent"]),
    confirmLogout() {
      this.confirmed = true;
      this.allowClose = false;
      this.signOut();
    },
  },
});
