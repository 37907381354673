import {
  Products,
  ApplicationStatuses,
  StipulationStatuses,
  PayoffVerificationStipulationTypes,
  ProcessingCategories,
  SurveyQuestionTypes,
  IncomeSourceTypes,
} from "@/config/enums";

export class ImageConstants {
  public static get BACKGROUND_GRAD(): string {
    return "background-grad.png";
  }
  public static get APP_ARCHIVE(): string {
    return "app-archive-rev.png";
  }
  public static get APPLY_STUDENT_LOAN(): string {
    return "apply-student-loan-rev.png";
  }
  public static get FOOTER_LOGO(): string {
    return "footer-logo-cusc.png";
  }
  public static get MODAL_CLOSE(): string {
    return "modal-close.png";
  }
}

export class StipulationStatusIds {
  public static get OPEN(): number {
    return StipulationStatuses.OPEN;
  }

  public static get COMPLETED(): number {
    return StipulationStatuses.COMPLETED;
  }

  public static get CU_OVERRIDE(): number {
    return StipulationStatuses.CU_OVERRIDE;
  }

  public static get CANCELLED(): number {
    return StipulationStatuses.CANCELLED;
  }
  public static get PENDING(): number {
    return StipulationStatuses.PENDING;
  }
}

export class ProductIds {
  public static get LOC(): number {
    return Products.LOC;
  }
  public static get REFI(): number {
    return Products.REFI;
  }
}

export class ApplicationStatusTypeIds {
  public static get NOT_SUBMITTED(): number {
    return ApplicationStatuses.NOT_SUBMITTED;
  }
  public static get EVALUATION(): number {
    return ApplicationStatuses.EVALUATION;
  }
  public static get SUBMITTED_AND_PROCESSING(): number {
    return ApplicationStatuses.SUBMITTED_AND_PROCESSING;
  }
  public static get COMPLETE(): number {
    return ApplicationStatuses.COMPLETE;
  }
  public static get DECLINED(): number {
    return ApplicationStatuses.DECLINED;
  }
  public static get CANCELLED(): number {
    return ApplicationStatuses.CANCELLED;
  }
  public static get SMR_DECLINED(): number {
    return ApplicationStatuses.SMR_DECLINED;
  }
  public static get RIGHT_TO_CANCEL(): number {
    return ApplicationStatuses.RIGHT_TO_CANCEL;
  }
}

export class DocumentNameConstants {
  public static get DESIGNATION_OF_AUTHORIZED_INDIVIDUAL_FORM(): string {
    return "Designation of Authorized Individual (Optional election)";
  }
}

export class PayoffVerificationStipulationTypeIds {
  public static get ConfirmPayoffRequest(): number {
    return PayoffVerificationStipulationTypes.ConfirmPayoffRequest;
  }
  public static get LoanAccountStatement(): number {
    return PayoffVerificationStipulationTypes.LoanAccountStatement;
  }
  public static get PayoffStatement(): number {
    return PayoffVerificationStipulationTypes.PayoffStatement;
  }
  public static get LoanResponsibilityAffidavit(): number {
    return PayoffVerificationStipulationTypes.LoanResponsibilityAffidavit;
  }
  public static get ResponsibilityForUnderPayment(): number {
    return PayoffVerificationStipulationTypes.ResponsibilityForUnderPayment;
  }
  public static get ServicerPayoffAddress(): number {
    return PayoffVerificationStipulationTypes.ServicerPayoffAddress;
  }
}

export class ProcessingCategoryIds {
  public static get Income(): number {
    return ProcessingCategories.Income;
  }
  public static get Citizenship(): number {
    return ProcessingCategories.Citizenship;
  }
  public static get Membership(): number {
    return ProcessingCategories.Membership;
  }
  public static get Identity(): number {
    return ProcessingCategories.Identity;
  }
  public static get SpousalConsent(): number {
    return ProcessingCategories.SpousalConsent;
  }
  public static get FinalReview(): number {
    return ProcessingCategories.FinalReview;
  }
  public static get LoanConfiguration(): number {
    return ProcessingCategories.LoanConfiguration;
  }
  public static get ESign(): number {
    return ProcessingCategories.ESign;
  }
  public static get Credit(): number {
    return ProcessingCategories.Credit;
  }
  public static get Misc(): number {
    return ProcessingCategories.Misc;
  }
  public static get PayoffVerification(): number {
    return ProcessingCategories.PayoffVerification;
  }
  public static get EligibilityVerification(): number {
    return ProcessingCategories.EligibilityVerification;
  }
  public static get RateTerm(): number {
    return ProcessingCategories.RateTerm;
  }
}

export class SurveyQuestionTypeIds {
  public static get TwoYearsIncome(): number {
    return SurveyQuestionTypes.TwoYearsIncome;
  }
  public static get SelectedCreditRange(): number {
    return SurveyQuestionTypes.SelectedCreditRange;
  }
  public static get MaritalStatus(): number {
    return SurveyQuestionTypes.MaritalStatus;
  }
  public static get SMREstimateLoanAmount(): number {
    return SurveyQuestionTypes.SMREstimateLoanAmount;
  }
  public static get EmploymentStatus(): number {
    return SurveyQuestionTypes.EmploymentStatus;
  }
  public static get EmployerName(): number {
    return SurveyQuestionTypes.EmployerName;
  }
}

export class IncomeSourceTypeIds {
  public static get PAYCHECK_FROM_EMPLOYER(): number {
    return IncomeSourceTypes.PAYSTUB;
  }
  public static get INCOME_FROM_BUSINESS_YOU_OWN(): number {
    return IncomeSourceTypes.SCHEDULE_C;
  }
  public static get SOCIAL_SECURITY_BENEFITS(): number {
    return IncomeSourceTypes.SOCIAL_SECURITY_BENEFITS;
  }
  public static get RETIREMENT(): number {
    return IncomeSourceTypes.RETIREMENT;
  }
  public static get INCOME_FROM_RENTAL_OR_INVESTMENT_PROPERTY(): number {
    return IncomeSourceTypes.SCHEDULE_E;
  }
  public static get OTHER_INCOME(): number {
    return IncomeSourceTypes.OTHER_INCOME;
  }
}