
import { defineComponent } from "vue";
import Modal from "../shared/Modal.vue";

export default defineComponent({
  components: {
    Modal,
  },
  props: {
    alertText: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    allowClose: true,
  }),
});
