
import { mapState, mapGetters, mapActions } from "vuex";
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import LogoutModal from "../../modals/LogoutModal.vue";
import useHtmlUtilities from "@/use/htmlUtilities";
import useFormatter from "@/use/formatter";

export default defineComponent({
  props: {
    externalLinks: {
      type: Boolean,
      default: false,
    },
  },
  name: "Header",
  setup(){
    const route = useRoute();
    const { decodeHTMLEncodedStr } = useHtmlUtilities();
    const { formatName } = useFormatter();
    return { decodeHTMLEncodedStr, route, formatName }
  },
  components: { LogoutModal },
  data: () => ({
    sidenavActive: false,
    showModal: false,
    showLogoutModal: false,
  }),
  computed: {
    ...mapState([
      "preferredName",
      "notifications",
      "profile",
      "lenderData",
      "configuration",
    ]),
    ...mapGetters(["cuLogoUrl"]),
    ...mapGetters("oidcStore", ["oidcIsAuthenticated", "oidcUser"]),
    notificationCount(): number {
      return (this.notifications || []).length as number;
    },
  },
  methods: {
    ...mapActions(["setNotifications", "setProfile", "setLenderData"]),
    ...mapActions("oidcStore", [
      "authenticateOidc",
      "removeOidcUser",
      "signOutOidcSilent",
    ]),
    login: function () {
      this.authenticateOidc();
    },
    toggleSidenav: function (open: boolean) {
      this.sidenavActive = open;
    },
  },
});
