/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { FieldSchema, FormField, FormSchema } from "../models/form";
import useFormHelpers from "@/use/formHelpers";

const { calculateAge } = useFormHelpers();

export default function useValidation() {
  function getErrorMessage(field: FormField | FieldSchema) {
    if (field.errorMessage) {
      return field.errorMessage;
    }

    switch (field.type) {
      case "email":
        return "Please provide a valid email address.";
      case "compare":
        return `Compared values do not match.`;
      case "phone":
        return "Please provide a valid phone number.";
      case "ssn":
        return "Please provide a valid SSN.";
      case "money":
        return "Please provide a valid amount.";
      default:
        return field.label ? `${field.label} is required.` : "Please provide a value.";
    }
  }

  async function validate(schema: FormField | FieldSchema, value: any, compare: any = undefined) {
    schema.touched = true;
    // Reset the error messages
    schema.errors = [];

    // If schema has custom validation, let them, handle it
    if (schema.validate) {
      schema.validate(schema, value, compare);
    } else {
      if (schema.required) {
        const trimmedValue = typeof value === "string" ? value.replace(/\s/g, "") : value;
        // Check if the field is requried first
        schema.valid = trimmedValue !== undefined && trimmedValue !== "" && trimmedValue !== null;
      }

      if (
        value === undefined ||
        value === "" ||
        value === null ||
        value === 0 ||
        !schema.required
      ) {
        // If empty we tell it to return the opposite of what is allowed
        schema.valid = !schema.required;
      } else {
        if (schema.type === "email") {
          // Check if the field needs email validation
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          schema.valid = re.test(value);
        } else if (schema.type === "date") {
          if (schema.subtype === "dob") {
            // Only allow ages between 0 and 150
            if (calculateAge(value) <= 0 || calculateAge(value) > 150) {
              schema.errors.push("Please provide a valid Date of Birth");
            }
          }
        } else if (schema.type === "phone") {
          // Check if the field needs phone number validation
          const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
          schema.valid = re.test(value);
        } else if (schema.type === "text") {
          // Does not allow empty input
          const cleanedInput = value?.trim();
          // Does not allow for urls into any input field
          const urlCheck = /^https?:\/\/.*$/.test(value);
          if (urlCheck === true) {
            schema.errors.push("Must not contain url.");
          }

          if (
            schema.name === "firstName" ||
            schema.name === "middleName" ||
            schema.name === "lastName"
          ) {
            // Only allow standard A-Z a-z values, single spaces and - '
            const specialCharacterCheck = /[^A-Za-z-' ]/.test(value);
            if (specialCharacterCheck === true) {
              schema.errors.push("Must not contain special characters.");
            }
            const numberCheck = /\d/.test(value);
            if (numberCheck === true) {
              schema.errors.push("Must not contain numeric values.");
            }

            // Passed the validation rules
            schema.valid = schema.errors.length <= 0;
          }

          if (schema.subtype === "address") {
            await validateAddressFields(schema, value);
          }

          schema.valid = cleanedInput?.length >= 1 && schema.errors.length <= 0;
        } else if (schema.type === "textarea") {
          const cleanedText = value.trim();
          if (cleanedText === undefined || cleanedText.length < 5 || cleanedText.length > 50) {
            schema.errors.push("Must be between 5 and 50 characters.");
          } else {
            schema.errors = [];
          }
          schema.valid = schema.errors.length <= 0;
        } else if (schema.type === "password") {
          // Test each rule individually for better readability

          // Adding the undefined checks because for some reason not all
          // rules would get hit if value was undefined...

          // At least 8 characters long and no more than 32 characters
          if (value === undefined || value.length < 8 || value.length > 32) {
            schema.errors.push("Must be between 8 and 32 characters.");
          }

          // Uppercase Alphabet.
          if (value === undefined || /[A-Z]/.test(value) === false) {
            schema.errors.push("Must contain an uppercase letter.");
          }

          // Lowercase Alphabet.
          if (value === undefined || /[a-z]/.test(value) === false) {
            schema.errors.push("Must contain a lowercase letter.");
          }

          // At least one number.
          if (value === undefined || /[0-9]/.test(value) === false) {
            schema.errors.push("Must contain a number.");
          }

          //Special Character.
          if (value === undefined || !new RegExp("[$@$!%*#?&]").test(value)) {
            schema.errors.push("Must contain a special character.");
          }

          // Passed all 5 password rules
          schema.valid = schema.errors.length <= 0;
        } else if (schema.type === "compare") {
          schema.valid =
            compare !== undefined && compare !== "" && compare !== null && value === compare;
        } else if (schema.type === "checkbox") {
          schema.valid = value === true;
        } else if (schema.type === "ssn") {
          const cleanedSsn = `${value}`.split("-").join("");
          const validDigits = /^\d{9}$/.test(cleanedSsn);
          schema.valid = validDigits && cleanedSsn.length === 9;
        } else if (schema.type === "money") {
          schema.valid = value !== undefined;
          if (value === undefined) {
            schema.errors.push("Amount is required");
          }

          if (schema?.subtype === "initialDrawAmount") {
            validateBorrowAmount(schema, value, compare);
          }

          if (schema?.subtype === "drawAmount") {
            // 1. Validate that the amount is a number
            if (value === undefined || value === "") {
              schema.errors.push("Amount is required.");
            }

            // 2. Validate that the amount is a whole number
            if (value === undefined || value % 1 !== 0) {
              schema.errors.push(
                "Only whole dollar values can be entered for the Requested Amount.",
              );
            }

            // 3. Validate that requested amount is greater than 0
            if (value === undefined || value === "0" || value.startsWith("0")) {
              schema.errors.push("Requested amount must be greater than 0.");
            }

            if (value === undefined || value === Number(0)) {
              schema.errors.push("Requested amount must be greater than 0.");
            }

            // 4. Validate the requested amount is below available to borrow amount
            if (compare.availableToBorrow && value > compare.availableToBorrow) {
              schema.errors.push(
                "The entered requested amount exceeds the available balance on this loan. Please enter a lower amount to proceed.",
              );
            }

            // 5. Validate that value meets minimum
            if (compare.minimum && value < compare.minimum) {
              schema.errors.push(
                `The entered requested amount does not meet the minimum required amount of $${compare.minimum}. Please enter an amount greater than or equal to $${compare.minimum} to proceed.`,
              );
            }

            schema.valid = schema.errors.length <= 0;
          }
          // Passed all rules
          schema.valid = schema.errors.length <= 0;
        } else if (schema.type === "zipcode") {
          // Handles 12345, 12345-6789
          const formatCheck1 = /^[0-9]{5}(?:-[0-9]{4})?$/.test(value);
          // Handles 123456789
          const formatCheck2 = /^[0-9]{9}?$/.test(value);

          if (value === undefined) {
            schema.errors.push("Invalid zip code");
          } else if (value.length === 5 && formatCheck1) {
            schema.valid = true;
          } else if (value.length === 10 && formatCheck1) {
            schema.valid = true;
          } else if (value.length === 9 && formatCheck2) {
            schema.valid = true;
          } else {
            schema.errors.push("Invalid zip code");
          }

          // Passed all checks
          schema.valid = schema.errors.length <= 0;
        }
      }

      if (schema.valid === false && schema.errors.length <= 0) {
        schema.errors = [getErrorMessage(schema)];
      }
    }
  }

  function validateForm(schema: FormSchema, formValue: any): boolean {
    let valid = true;

    for (const [key, value] of Object.entries(schema)) {
      const keySchema = value as any;
      if (keySchema.type === "autocomplete") {
        const compareValue = formValue[key] || {};
        validate(value as FormField, compareValue[value.autocompleteProperty || ""] || "");
      } else if (keySchema.validate) {
        keySchema.validate(value as FormField, formValue[key], formValue[keySchema.compareField]);
      } else {
        validate(value as FormField, formValue[key], formValue[keySchema.compareField]);
      }
      valid = valid && (value as any).valid;
    }

    return valid;
  }

  async function validateAddressFields(schema: FormField | FieldSchema, value: any) {
    schema.touched = true;
    // Reset the error messages
    schema.errors = [];

    // character limits: handled by maxlength input property

    if (schema.name === "addrLine1") {
      // Only allow standard A-Z a-z 0-9 values, single space, and - '
      const specialCharacterCheck = /[^A-Za-z0-9-' ]/.test(value);
      if (specialCharacterCheck === true) {
        schema.errors.push("Must not contain special characters.");
      }
    }

    if (schema.name === "city") {
      // Only allow standard A-Z a-z values, and single spaces
      const invalidCharCheck = /[^A-Za-z-' ]/.test(value);
      if (invalidCharCheck === true) {
        schema.errors.push("Sorry, only letters (a-z) are allowed.");
      }
    }

    schema.valid = schema.errors.length <= 0;
  }

  async function validateZipcode(schema: FormField | FieldSchema, value: any) {
    schema.touched = true;
    // Reset the error messages
    schema.errors = [];
    // Handles 12345, 12345-6789
    const formatCheck1 = /^[0-9]{5}(?:-[0-9]{4})?$/.test(value);
    // Handles 123456789
    const formatCheck2 = /^[0-9]{9}?$/.test(value);

    if (value === undefined) {
      schema.errors.push("Invalid zip code");
    } else if (value.length === 5 && formatCheck1) {
      schema.valid = true;
    } else if (value.length === 10 && formatCheck1) {
      schema.valid = true;
    } else if (value.length === 9 && formatCheck2) {
      schema.valid = true;
    } else {
      schema.errors.push("Invalid zip code");
    }

    // Passed all checks
    schema.valid = schema.errors.length <= 0;
  }

  function validateAddressLine2Fields(
    unitType: FieldSchema,
    unitNumber: FieldSchema,
    unitTypeValue: any,
    unitNumberValue: any,
  ): boolean {
    let valid = true;

    if (unitTypeValue && unitNumberValue) {
      unitType.required = true;
      unitNumber.required = true;
      valid = true;
    } else if (unitTypeValue && !unitNumberValue) {
      unitType.required = true;
      unitNumber.required = true;
      valid = false;
    } else if (!unitTypeValue && unitNumberValue) {
      unitType.required = true;
      unitNumber.required = true;
      valid = false;
    } else if (!unitTypeValue && !unitNumberValue) {
      unitType.required = false;
      unitNumber.required = false;
      unitType.valid = true;
      unitNumber.valid = true;
      unitType.errors = [];
      unitNumber.errors = [];
      valid = true;
    }

    return valid;
  }

  async function validateBorrowAmount(schema: any, value: any, compare: any) {
    schema.errors = [];

    if (value !== null) {
      const amount =
        (typeof value === "string" && value !== undefined) || value !== ""
          ? parseInt(value)
          : value;
      schema.valid =
        amount <= compare &&
        ((schema.allowZero === true && amount === 0) ||
          (schema.allowZero === false && amount > 0) ||
          amount >= 1000);

      if (schema.valid === false) {
        if (amount > compare) {
          schema.errors = [
            "The amount requested is more than the total line of credit you've been approved for.",
          ];
        } else {
          schema.errors = ["An amount to borrow is required."];
        }
      }
    }
  }

  return { validate, validateForm, validateAddressLine2Fields, validateBorrowAmount };
}
