import { Module } from "vuex";

const fileUploaderModule: Module<any, any> = {
  namespaced: true,
  state: {
    uploadedFiles: [] as any[],
  },
  mutations: {
    SET_UPLOADED_FILES(state: any, files: any) {
      state.uploadedFiles = files;
    },
  },
  actions: {
    setUploadedFiles({ commit }, payload) {
      commit("SET_UPLOADED_FILES", payload);
    },
  },
  getters: {
    uploadedFiles(state: any) {
      return state.uploadedFiles;
    },
  },
};
export default fileUploaderModule;
