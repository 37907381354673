
import { defineComponent, ref } from "vue";
import { mapGetters, useStore } from "vuex";
import { $api } from "@/services/api";
import Loader from "@/components/shared/Loader.vue";
export default defineComponent({
  setup() {
    const store = useStore();
    const submittingRequest = ref<boolean>(false);
    const previousButtonDisabled = ref<boolean>(false);

    return { store, submittingRequest, previousButtonDisabled };
  },
  components: { Loader },
  emits: ["goback", "complete", "getLoanDetails"],
  props: {
    referenceId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    error: false,
    reviewConfirmation: false,
    postErrors: [] as string[],
  }),
  computed: {
    ...mapGetters("fundsModule", ["DISBURSEMENTS", "DrawRequest"]),
  },
  methods: {
    async submitRequest() {
      console.log("this.DrawRequest", this.DrawRequest);

      const body = this.DrawRequest;
      this.error = false;
      this.submittingRequest = true;
      this.previousButtonDisabled = true;

      $api.originator
        .postDrawRequest(this.referenceId, body)
        .then((result: any) => {
          if (result.error) {
            this.error = true;
            this.postErrors = [result.msg];
          } else {
            this.store.commit("fundsModule/resetAllStates");
            this.submittingRequest = false;
            this.loading = false;
            this.$emit("complete");
          }
        })
        .catch(error => {
          this.error = true;
          console.error(error);
        });

      this.previousButtonDisabled = false;
    },
  },
  mounted() {
    this.loading = false;
  },
});
