
import { defineComponent } from "vue";
import { mapState } from "vuex";
import useFormatter from "@/use/formatter";
import Step from "@/components/shared/Step.vue";
import BaseFormField from "@/components/shared/BaseFormField.vue";
import { $api } from "@/services/api";
import usePaymentOptions from "@/use/paymentOptions";
import NextButton from "@/components/shared/NextButton.vue";
import { FormSchema } from "@/models/form";
import Modal from "@/components/shared/Modal.vue";
import TooltipComponent from "@/components/shared/TooltipComponent.vue";
import Icon from "@/components/shared/Icon.vue";

export default defineComponent({
  setup() {
    const { formatNumber } = useFormatter();
    const {
      getPaymentOptionHeader,
      getRateDescription,
      getRateOptionHeader,
      getRepaymentTermHeader,
      getDgRateDescription,
      formatRatePercentages,
      formatStartOfQuarter,
    } = usePaymentOptions();

    return {
      formatNumber,
      getPaymentOptionHeader,
      getRateDescription,
      getRateOptionHeader,
      getRepaymentTermHeader,
      getDgRateDescription,
      formatRatePercentages,
      formatStartOfQuarter,
    };
  },
  components: { Step, BaseFormField, NextButton, Modal, TooltipComponent, Icon },
  props: {
    application: {
      type: Object,
      required: true,
    },
    applicant: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isCurrent: {
      type: Boolean,
      required: false,
    },
    isCurrentSubStep: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
    },
    initialDrawAmount: {
      type: Number,
      default: 0,
    },
    updateLoanOptions: {
      type: Boolean,
    },
  },
  data: () => ({
    currentSubStep: 3,
    fixedRepaymentTerm: 0,
    variableRepaymentTerm: 0,
    fixedRateInterestRate: 0,
    variableRateInterestRate: 0,
    repaymentTypes: [] as any,
    rateTypes: [] as any,
    interestRate: 0,
    loanTerm: 10,
    principal: 0,
    interestOnlyPayments: 0,
    originationFeeRate: 0,
    gettingRatePlans: (true as boolean) || undefined,
    selectedPaymentOption: undefined as any,
    selectedRatePlan: undefined as any,
    defaultRatePlans: [] as any[],
    appRatePlans: [] as any[],
    requestedAmount: undefined as any,
    options: [
      { type: "Fixed Interest Rate", rateTypeId: 1 },
      { type: "Variable Interest Rate", rateTypeId: 2 },
    ] as any,
    defaultPaymentOptions: [
      {
        repaymentTypeId: 2,
        repaymentTypeName: "Deferred",
      },
      {
        repaymentTypeId: 3,
        repaymentTypeName: "Interest",
      },
      {
        repaymentTypeId: 4,
        repaymentTypeName: "Regular",
      },
    ] as any[],
    schema: {
      paymentOption: {
        name: "paymentOption",
        type: "radio",
        required: true,
        hideLabel: true,
      },
    } as FormSchema,
  }),
  watch: {
    updateLoanOptions: {
      handler(newValue, oldValue) {
        if (newValue === true && oldValue === false) {
          this.goBackToPortal();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(["appData", "lenderData"]),
    schoolSelected(): boolean | undefined {
      return this.appData.application?.schoolConfiguration?.schoolId !== null;
    },
  },
  methods: {
    goBack() {
      if (this.schoolSelected === true) {
        this.$emit("previous", true);
      } else {
        this.$emit("skipBorrowAmount", true);
      }
    },
    async getInitialDrawAmount() {
      // Check for initial draw info
      let initialDrawRequest = await $api.applications.getInitialDraw(this.appData.application.id);
      this.requestedAmount = initialDrawRequest?.amount || undefined;
    },
    updatePaymentOption(payment: any) {
      this.selectedPaymentOption = payment;
    },
    async selected() {
      // only updates if ratePlans (from DB) for the application exist
      await this.updateLoanConfiguration();

      if (this.schoolSelected) {
        this.$emit("complete", {
          schoolSelected: this.schoolSelected,
          selectedByApplicantId: this.applicant?.id,
          selectedPaymentOption: this.selectedPaymentOption,
          selectedRatePlanId: this.selectedRatePlan?.id,
        });
      } else {
        this.$emit("complete", {
          schoolSelected: this.schoolSelected,
          repaymentTypeId: this.selectedPaymentOption.repaymentTypeId,
        });
      }
    },
    async goBackToPortal() {
      // If it is selected
      if (this.selectedPaymentOption) {
        this.updatePaymentOption(this.selectedPaymentOption);

        // Temporary until orchestration set up
        if ((this.appData.application?.ratePlans || []).length > 0) {
          // Get updated selected rate plan Id
          this.selectedRatePlan = this.appData?.application?.ratePlans.find(
            (a: any) => a.rateTypeId === this.selectedPaymentOption?.rateTypeId,
          );
          this.$emit("complete", {
            selectedPaymentOptiont: this.selectedPaymentOption,
            schoolSelected: this.schoolSelected,
            selectedByApplicantId: this.applicant?.id,
            selectedPaymentOption: this.selectedPaymentOption,
            selectedRatePlanId: this.selectedRatePlan?.id,
            interestRate: this.selectedRatePlan?.rate,
            rateTypeId: this.selectedPaymentOption?.rateTypeId,
          });
        }
      } else {
        this.$emit("complete", { error: true });
      }
    },
    async getRatePlans() {
      this.appRatePlans = await $api.applications.getApplicationRatePlansByApplicationId(
        this.appData.application?.id,
        this.appData.application?.lenderId,
      );

      if (this.appRatePlans && this.appRatePlans.length > 0) {
        this.appRatePlans = this.appRatePlans.sort((a: any, b: any) => a.rateTypeId - b.rateTypeId);
      }
      console.log("getRatePlans() this.appRatePlans", this.appRatePlans);
    },

    async updateLoanConfiguration() {
      // Get updated selected rate plan Id
      this.selectedRatePlan = this.appData?.application?.ratePlans.find(
        (a: any) => a.rateTypeId === this.selectedPaymentOption?.rateTypeId,
      );

      // Get updated selected rate plan I
      this.appData.application.loanConfiguration = {
        applicationId: this.appData.application.id,
        selectedRatePlanId: this.selectedRatePlan.id,
        selectedByApplicantId: this.applicant?.id,
        repaymentTypeId: this.selectedPaymentOption.repaymentTypeId,
      };
      let response = await $api.applications.addOrUpdateAppLoanConfig(
        this.appData.application.loanConfiguration,
      );
    },
    async setLoanConfiguration() {
      const validLoanConfiguration =
        this.appData?.loanConfiguration !== null && this.appData?.loanConfiguration !== undefined;

      if (validLoanConfiguration) {
        let selectedRatePlanId = this.appData?.application?.loanConfiguration?.ratePlanId;
        let selectedRateTypeId = this.appData?.application?.loanConfiguration?.rateTypeId;
        let selectedRepaymentTypeId = this.appData?.application?.loanConfiguration?.repaymentTypeId;

        if (selectedRatePlanId > 0) {
          let selected = this.appRatePlans?.find((r: any) => r.id === selectedRatePlanId);

          if (selected) {
            if (selected?.repayments.length > 0) {
              this.selectedPaymentOption = (selected?.repayments || []).find(
                (payment: any) => payment.repaymentTypeId === selectedRepaymentTypeId,
              );
              await this.updatePaymentOption(this.selectedPaymentOption);
            }
          }
        }
      }
    },
  },

  async mounted() {
    await this.getRatePlans();
    await this.getInitialDrawAmount();
    await this.setLoanConfiguration();
  },
});
