import { createApp } from "vue";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Maska from "maska";
import VueGtag from "vue-gtag";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { faCircle as farCircle } from "@fortawesome/free-regular-svg-icons";
import { faCircleInfo, faPlus, faMinus, faCircleExclamation, faPen, faCircleCheck, faDownload, faClock, faFileContract, faFileUpload, faCloudArrowDown, faHeadset, faCircle as fasCircle, faPhone, faEnvelope as fasEnvelope } from "@fortawesome/free-solid-svg-icons";
library.add(faCircleExclamation, faPen, faCircleCheck, faDownload, faClock, faFileContract, faFileUpload, faCloudArrowDown, faHeadset, farCircle, fasCircle, faPhone, fasEnvelope);

// Needed for Google Analytics
import { getEnvironment } from "./config/oidc";

axios.interceptors.request.use(
  (config: any) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${store.getters["oidcStore/oidcAccessToken"]}`,
      },
    };
  },
  (error: any) => Promise.reject(error)
);

const app = createApp(App);

// Needed for Google Analytics
const environment = getEnvironment();
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('font-awesome-layers', FontAwesomeLayers)

app
.use(store)
.use(router)
.use(Maska)
.use(VueGtag, {
  config: { id: environment.googleAnalyticsId }
})

app.provide("gtag", app.config.globalProperties.$gtag);
app.mount("#app");
