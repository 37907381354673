import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c5851f64"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "step" }
const _hoisted_2 = {
  key: 0,
  class: "step-header"
}
const _hoisted_3 = {
  key: 0,
  class: "subheader"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 2,
  class: "postheader"
}
const _hoisted_6 = {
  key: 3,
  class: "hint"
}
const _hoisted_7 = { class: "step-content" }
const _hoisted_8 = { class: "step-btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$slots.subheader || _ctx.$slots.header)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.$slots.subheader)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, [
                _renderSlot(_ctx.$slots, "subheader", {}, undefined, true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.$slots.header)
            ? (_openBlock(), _createElementBlock("h1", _hoisted_4, [
                _renderSlot(_ctx.$slots, "header", {}, undefined, true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.$slots.postheader)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
                _renderSlot(_ctx.$slots, "postheader", {}, undefined, true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.$slots.hint)
            ? (_openBlock(), _createElementBlock("p", _hoisted_6, [
                _renderSlot(_ctx.$slots, "hint", {}, undefined, true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _mergeProps({ class: "content" }, _ctx.$attrs), [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 16)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
    ])
  ]))
}