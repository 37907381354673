import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b39d288c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createBlock(_component_modal, {
    class: "alert-modal",
    closeBtn: _ctx.allowClose
  }, {
    header: _withCtx(() => []),
    footer: _withCtx(() => []),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.alertText), 1)
    ]),
    _: 1
  }, 8, ["closeBtn"]))
}