
import { defineComponent, reactive } from "vue";
import { useStore, mapGetters } from "vuex";
import useFormatter from "@/use/formatter";
import useFormHelpers from "@/use/formHelpers";
import useValidation from "@/use/validation";
import Loader from "@/components/shared/Loader.vue";
import { Disbursement } from "@/models";
import { FieldSchema, FormSchema } from "@/models/form";
import BaseFormField from "../shared/BaseFormField.vue";
export default defineComponent({
  components: { Loader, BaseFormField },
  props: {
    loan: {
      type: Object,
      required: true,
    },
  },
  emits: ["complete", "goback", "close", "update:modelValue"],
  setup() {
    const { formatMoney, formatCurrency } = useFormatter();
    const { validate, validateForm } = useValidation();
    const { months, years } = useFormHelpers();
    const store = useStore();
    let schema = reactive({
      amount: {
        name: "amount",
        type: "money",
        subtype: "drawAmount",
        hideLabel: true,
        required: true,
        allowZero: false,
        allowDecimals: false,
        errors: [] as string[],
      },
    }) as FormSchema;
    let errors = reactive([]) as string[];

    function isNumberKey(evt: any) {
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) return false;

      return true;
    }

    return {
      errors,
      schema,
      store,
      validate,
      validateForm,
      months,
      years,
      formatMoney,
      formatCurrency,
      isNumberKey,
    };
  },
  data: () => ({
    loading: true,
    requestedAmount: "",
    amountError: "",
  }),
  computed: {
    ...mapGetters("fundsModule", [
      "SchoolName",
      "AcademicPeriod",
      "AmountRequested",
      "ApprovedLoanAmount",
      "Loan",
			"EnrollmentStatusName",
    ]),
    availableToBorrow(): string {
      let number = 0;
      if (this.Loan?.availableToBorrow) {
        number = this.Loan?.availableToBorrow;
      }
      return this.formatMoney(number);
    },
    loanAmount(): string {
      let number = 0;
      if (this.Loan?.loanAmount) {
        number = this.Loan?.loanAmount;
      }
      return this.formatMoney(number);
    },
    lastRequestedDraw(): string {
      let number = 0;

      if (this.Loan?.draws) {
        number =
          this.Loan?.draws[this.Loan?.draws.length - 1 || 0]?.requestedAmount ||
          0;
      }

      return this.formatMoney(number);
    },
    validRequestedAmount(): boolean {
      let valid = true;
      const amount = Number(this.requestedAmount.replace(/,/g, ""));
      const availableToBorrowToInt = parseInt(
        this.availableToBorrow.replace(/,/g, "").replace("$", "")
      );

      // 1. Validate that the amount is a number
      if (isNaN(amount)) {
        valid = false;
      }
      // 2. Validate that the amount is in whole dollars, no cents
      else if (amount % 1 !== 0) {
        valid = false;
      }
      // Validate that the requested amount meets minimum $1000 for initial draws and $250 for subsequent draws
      if (this.requestedAmount !== "" && amount < this.Loan?.minimum) {
        valid = false;
      }
      // 3. Validate the requested amount is below available to borrow amount
      else if (availableToBorrowToInt && amount > availableToBorrowToInt) {
        valid = false;
      }

      return valid;
    },
  },
  methods: {
    completeRequestAmount() {
      this.store.commit("fundsModule/resetDisbursement");
      const terms = this.AcademicPeriod.split("-");
      const cleanRequestAmount = this.requestedAmount.replace(/,/g, "");
      const requestedAmount = parseInt(cleanRequestAmount);
      terms.forEach((term: any) => {
        const disburse: Disbursement = {
          schoolName: this.SchoolName,
          enrollmentTerm: term.trim(),
          requestedAmount: `$${new Intl.NumberFormat("en-US").format(
            requestedAmount / terms.length
          )}`,
					enrollmentStatusName: this.EnrollmentStatusName,
        };
        this.store.commit("fundsModule/setRequestAmount", cleanRequestAmount);
        this.store.commit("fundsModule/setDisbursement", disburse);
      });

      this.$emit("complete", { step: 4 });
      this.loading = true;
    },
  },
  mounted() {
    this.loading = false;

    //Check if there was a previous set amount..
    this.requestedAmount = this.AmountRequested;
  },
});
