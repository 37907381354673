
import { defineComponent } from "vue";
import Modal from "../shared/Modal.vue";

export default defineComponent({
  components: {
    Modal,
  },
  data: () => ({
    allowClose: false,
  }),
});
