
import useApplications from "@/use/application";
import { defineComponent } from "vue";
import { useRoute,  } from "vue-router";
import { mapActions, mapGetters, mapState, useStore } from "vuex";
import Loader from "../components/shared/Loader.vue";
import { $api } from "@/services/api";

export default defineComponent({
  setup() {
    const store = useStore();
    const route = useRoute();
    const { getUserApplications } = useApplications();
    return { getUserApplications, route };
  },
  components: {
    Loader,
  },
  computed: {
    ...mapState(["applications"]),
    ...mapGetters("oidcStore", ["oidcUser"]),
  },
  methods: {
    ...mapActions(["setApplications"]),
    async getUserInfo() {
      const query = this.route.query as any;

     const userApps = await this.getUserApplications(this.oidcUser.sub);
      this.setApplications(userApps);

      if (this.applications.length === 1) {

        const current = await $api.applications.getUserApplicationByRefId(this.oidcUser.sub, this.applications[0].referenceId, 0);

        console.log('getUserApplicationByRefId', current);
        console.log('Home.vue', this.route)
        // set slc-lid
        localStorage.setItem("slc-lid", this.applications[0].lenderId);
        this.$router.push({
          path: `/applications/${this.applications[0].referenceId}`,
          query,
        });
      } else {
        // Assuming they have more than one we send them to applcations instead
        this.$router.push({ path: "/applications", query });
      }
    },
  },
  mounted() {
    console.log("Home component mounted", this.oidcUser);
    this.getUserInfo();
  },
});
