export default function useEnrollmentTerms(): any {
  function checkEnrollmentTerm(startStr: string, endStr: string) {
    const start = Date.parse(startStr);
    const end = Date.parse(endStr);
    const d = Date.now();

    // For testing purposes
    // const d = Date.parse("2023/12/31");

    return d.valueOf() >= start.valueOf() && d.valueOf() <= end.valueOf();
  }

  function getEnrollmentTermEnum(academicTerm: string): number {
    const currentYear = new Date().getFullYear();

    switch (academicTerm) {
      case `Fall ${currentYear}`:
        return 1;
      case `Spring ${currentYear}`:
        return 2;
      case `Fall ${currentYear - 1} - Spring ${currentYear}`:
        return 3;
      default:
        return 0;
    }
  }
  return {
    getEnrollmentTermEnum,
    checkEnrollmentTerm,
  };
}
