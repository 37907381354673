export const oidcSettings = {
  responseType: "id_token token",
  scope: "openid profile",
  automaticSilentRenew: true,
  automaticSilentSignin: true,
};

export enum EnvType {
  local = "LOCAL",
  localci = "LOCALCI",
  localqa = "LOCALQA",
  ci = "CI",
  qa = "QA",
  uat = "UAT",
  prod = "PROD",
}

export interface OidcSettings {
  env: EnvType;
  authority: string;
  clientId: string;
  redirectUri: string;
  googleAnalyticsId: string;
}

export interface OidcEnvironments {
  local: OidcSettings;
  localci: OidcSettings;
  localqa: OidcSettings,
  ci: OidcSettings;
  qa: OidcSettings;
  uat: OidcSettings;
  prod: OidcSettings;
}

export const oidcEnvironments: OidcEnvironments = {
  local: {
    env: EnvType.local,
    authority: "https://localhost:44351",
    clientId: "ApplicantPortal",
    redirectUri: "https://localhost:44379/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV,
  },
  localci: {
    env: EnvType.localci,
    authority: "https://identity-dev.studentchoice.org",
    clientId: "ApplicantPortal",
    redirectUri: "https://localhost:44378/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV,
  },
  localqa: {
    env: EnvType.localqa,
    authority: "https://identity-qa.studentchoice.org",
    clientId: "ApplicantPortal",
    redirectUri: "https://localhost:44377/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV,
  },
  ci: {
    env: EnvType.ci,
    authority: "https://identity-dev.studentchoice.org",
    clientId: "ApplicantPortal",
    redirectUri:
      "https://app-lz02-ci-appptl-eus.azurewebsites.net/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV,
  },
  qa: {
    env: EnvType.qa,
    authority: "https://identity-qa.studentchoice.org",
    clientId: "ApplicantPortal",
    redirectUri:
      "https://app-lz02-qa-appptl-eus.azurewebsites.net/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_PROD,
  },
  uat: {
    env: EnvType.uat,
    authority: "https://identity-uat.studentchoice.org",
    clientId: "applicant-portal",
    redirectUri: "https://portal.uat.studentchoice.org/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_DEV,
  },
  prod: {
    env: EnvType.prod,
    authority: "https://identity.studentchoice.org",
    clientId: "applicant-portal",
    redirectUri: "https://portal.studentchoice.org/oidc-callback",
    googleAnalyticsId: process.env.VUE_APP_GOOGLE_ANALYTICS_ID_PROD,
  },
};

export function getEnvironment(): OidcSettings {
  // default to PROD
  let environment = oidcEnvironments.prod;
  const host = window.location.hostname;
  const port = window.location.port;

  if (host.includes("localhost") && port === "44379") {
    environment = oidcEnvironments.local;
  } else if (host.includes("localhost") && port === "44378") {
    environment = oidcEnvironments.localci;
  } else if (host.includes("localhost") && port === "44377") {
    environment = oidcEnvironments.localqa;
  }
  else if (host.includes("-ci")) {
    environment = oidcEnvironments.ci;
  } else if (host.includes("-qa")) {
    environment = oidcEnvironments.qa;
  } else if (host.includes("-uat") || host.includes(".uat.")) {
    environment = oidcEnvironments.uat;
  }

  // disable logging for UAT and PROD environments
  if (environment.env === EnvType.uat || environment.env === EnvType.prod) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.log = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.warn = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.debug = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.info = () => {};
  }

  return environment;
}
