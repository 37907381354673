
import { defineComponent, ref, reactive, computed } from "vue";
import Step from "@/components/shared/Step.vue";
import BaseFormField from "@/components/shared/BaseFormField.vue";
import { mapActions, mapState, useStore } from "vuex";
import useValidation from "@/use/validation";
import { FormSchema, Option } from "@/models/form";
import { $api } from "@/services/api";
import { School } from "@/models/school";
import useFormHelpers from "@/use/formHelpers";
import useEnrollmentTerms from "@/use/enrollmentTerm";
import useFormatter from "@/use/formatter";
import CircleLoader from "@/components/shared/CircleLoader.vue";
import NextButton from "@/components/shared/NextButton.vue";
import Modal from "@/components/shared/Modal.vue";

export default defineComponent({
  setup() {
    const store = useStore();
    const { validateForm } = useValidation();
    const { months, years } = useFormHelpers();
    const { stringToBoolean, formatDateYYYYMMDD } = useFormatter();
    const { checkEnrollmentTerm } = useEnrollmentTerms();

    const schema = reactive({
      schoolUndecided: {
        type: "radio",
        label: "",
        hideLabel: false,
        required: false,
        errorMessage: "Please select yes or no",
        tooltipLocation: "bottom",
      },
      school: {
        name: "school",
        type: "autocomplete",
        label: "School Name",
        required: true,
        autocompleteProperty: "schoolName",
      },
      academicTerm: {
        name: "academicTerm",
        type: "select",
        label: "Academic Term",
        required: true,
      },
      enrollmentStatusId: {
        name: "enrollmentStatusId",
        type: "select",
        label: "Enrollment Status",
        tooltipLocation: "bottom",
        required: true,
      },
      graduationDateMonth: {
        name: "graduationDateMonth",
        type: "select",
        label: "Graduation Date",
        required: true,
        errorMessage: "Grad month is required.",
      },
      graduationDateYear: {
        name: "graduationDateYear",
        type: "select",
        required: true,
        errorMessage: "Grad year is required.",
      },
    } as FormSchema);

    const invalidEnrollmentTerm = ref<boolean>(false);

    let schoolInfo = ref({ school: {} } as any);

    const enrollmentStatuses = computed(() => store.state.enrollmentStatuses);
    const enrollmentStatusOptions = computed(() => {
      const academicTerm = schoolInfo?.value?.academicTerm
        ? JSON.parse(schoolInfo?.value?.academicTerm)
        : undefined;

      return schoolInfo.value?.academicTerm &&
        academicTerm?.label?.toLowerCase()?.includes("summer")
        ? enrollmentStatuses.value
        : enrollmentStatuses.value?.filter((o: Option) => o.label !== "Less Than Half-Time");
    });

    async function getData() {
      if ((store.state.schools || []).length <= 0) {
        const res = await $api.school.getSchoolsByProgram(
          store?.state?.appData?.application?.programTypeId,
          store?.state?.appData?.application?.productTypeId,
          store?.state?.appData?.application?.lenderId,
        );
        store.dispatch("setSchools", res.schools);
      }
      if ((store.state.enrollmentTerms || []).length <= 0) {
        const res = await $api.lookups.getEnrollmentTermsLookups();
        store.dispatch("setEnrollmentTerms", res);
      }
    }

    return {
      getData,
      validateForm,
      invalidEnrollmentTerm,
      months,
      years,
      schema,
      store,
      stringToBoolean,
      formatDateYYYYMMDD,
      checkEnrollmentTerm,
      schoolInfo,
      enrollmentStatuses,
      enrollmentStatusOptions,
    };
  },
  props: {
    application: {
      type: Object,
      required: true,
    },
    applicant: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isCurrent: {
      type: Boolean,
      required: false,
    },
    isEditing: {
      type: Boolean,
      required: false,
    },
    info: {
      type: Object,
    },
    term: {
      type: String,
    },
    isCurrentSubStep: {
      type: Boolean,
      default: false,
    },
    updateSchoolInfo: {
      type: Boolean,
    },
  },
  components: { Step, BaseFormField, CircleLoader, NextButton, Modal },
  emits: ["previous", "close", "complete"],
  data: () => ({
    path: "",
    gettingData: true,
    currentSubStep: 1,
    schoolUndecided: undefined as boolean | undefined,
    showConfirmationQuestion: undefined as boolean | undefined,
    updatedSchoolInfo: { school: {} } as any,
    enrollmentTerms: [] as any[],
    schoolPlanOptions: [
      {
        label: "Yes",
        value: false,
      },
      {
        label: "No",
        value: true,
      },
    ],
  }),
  computed: {
    ...mapState(["appData", "schools"]),
    selectedSchool() {
      let option = {} as any;
      option = this.schools.find(
        (t: any) => t.id === this.appData?.application?.schoolConfiguration?.schoolId,
      );
      return option || undefined;
    },
    selectedTerm(): any {
      let option = {} as any;
      option = this.enrollmentTerms.find(
        (t: any) =>
          t.label ===
          (this.term !== ""
            ? this.term
            : this.appData?.application?.schoolConfiguration?.academicTerm),
      );

      return option || undefined;
    },
    selectedEnrollmentStatus(): any {
      return this.enrollmentStatuses?.find(
        (o: Option) => o.value === this.appData?.application?.schoolConfiguration?.enrollmentStatus,
      );
    },
  },
  watch: {
    showConfirmationQuestion(value) {
      this.schema.schoolUndecided.required = value;
    },
    updateSchoolInfo: {
      handler(newValue, oldValue) {
        if (newValue === true && oldValue === false) {
          this.goBackToPortal();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["setSchools", "setAppData"]),
    async initialize() {
      await this.getData();

      if (this.store?.state?.enrollmentTerms?.length > 0) {
        this.enrollmentTerms = (this.store.state.enrollmentTerms || []).map((o: any) => {
          return {
            label: o.description,
            value: JSON.stringify({
              label: `${o.description}`,
              id: `${o.id}`,
            }),
          };
        });
        return this.enrollmentTerms;
      } else {
        this.gettingData = false;
      }
    },
    async setSchoolInfo() {
      this.gettingData = true;

      await this.initialize();

      // For LOSCore
      if (!this.appData.application.schoolConfiguration) {
        this.appData.application.schoolConfiguration = {};
      }

      // for LOSCORE || CDA
      const school = this.schools.find(
        (s: School) =>
          s.id === this.application?.schoolId ||
          s.id === this.appData?.application?.schoolConfiguration?.schoolId ||
          s.id === this.appData?.application?.schoolId ||
          s.schoolName === this.appData.application.schoolConfiguration?.schoolName,
      );

      this.schoolInfo = {
        school: school || {},
      };
      this.schoolInfo.schoolUndecided =
        this.appData.application.schoolConfiguration.schoolUndecided;

      if (this.appData?.application?.schoolConfiguration?.graduationDate) {
        const date = new Date(this.appData.application.schoolConfiguration.graduationDate);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        this.schoolInfo.graduationDateMonth = month;
        this.schoolInfo.graduationDateYear = year;
        this.schoolInfo.graduationDate = date;
      }

      if (this.appData.application.schoolConfiguration?.academicTerm || this.info?.academicTerm) {
        this.schoolInfo.academicTerm = this.selectedTerm?.value;
      }

      if (this.appData.application.schoolConfiguration?.enrollmentStatus) {
        this.schoolInfo.enrollmentStatusId = this.selectedEnrollmentStatus?.value;
      }
      this.gettingData = false;
    },
    setPath(path: string) {
      this.path = path;
    },
    setSchoolUndecidedLabel(path: string) {
      if (path === "Primary Applicant") {
        this.schema.schoolUndecided.label = "Have you confirmed the school you will be attending?";
      } else if (path === "Co-Applicant") {
        this.schema.schoolUndecided.label =
          "Have you confirmed the school your student will be attending?";
      } else {
        ("Have you confirmed the school your student will be attending?");
      }
    },
    setAlreadyChosenPath(applicantType: any) {
      // The user has already chosen a value from the ChoosePath
      switch (applicantType) {
        case 1:
        case "Primary Applicant":
          this.setPath("Primary Applicant");
          break;
        case 2:
        case "Co-Applicant":
          this.setPath("Co-Applicant");
          break;
        default:
      }

      this.setSchoolUndecidedLabel(this.path);
    },
    goBackToPortal() {
      const valid = this.validateForm(this.schema, this.schoolInfo);
      if (valid) {
        // Default to the end of the month for now
        const gradDate = new Date(
          this.schoolInfo.graduationDateYear,
          this.schoolInfo.graduationDateMonth,
          0,
        );

        const academicTerm = JSON.parse(this.schoolInfo.academicTerm);

        if (!this.appData.application.schoolConfiguration) {
          this.appData.application.schoolConfiguration = {};
        }

        const enrollmentTerm = this.store.state.enrollmentTerms.find(
          (p: any) => p.description == academicTerm.label,
        );
        if (enrollmentTerm) {
          const endDate = new Date(enrollmentTerm.endDate);
          const date1 = Date.UTC(gradDate.getFullYear(), gradDate.getMonth(), gradDate.getDate());
          const date2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

          const isBeforeDate = date1 < date2;
          const difference = Math.floor((date1 - date2) / 1000 / 60 / 60 / 24);

          if (difference < 0 || isBeforeDate) {
            this.invalidEnrollmentTerm = true;
          } else {
            this.invalidEnrollmentTerm = false;
            this.schoolInfo.graduationDate = gradDate;
            this.schoolInfo.academicTerm = academicTerm;
            this.schoolInfo.academicTermId = academicTerm.id;
            this.schoolInfo.selectedTerm = academicTerm.label;

            this.$emit("complete", this.schoolInfo);
          }
        }
      }
    },
    getSelectedTermMonth(dateIn: any) {
      const date = new Date(dateIn);
      const month = date.getMonth() + 1;
      return month;
    },
  },
  async mounted() {
    await this.setAlreadyChosenPath(this.appData?.currentApplicant?.applicantTypeName);
    await this.getData();
    await this.setSchoolInfo();

    // Story 4063: No should be selected by default if they answered "No" on the initial School Plans page
    this.showConfirmationQuestion = this.appData.application.schoolConfiguration.schoolUndecided;
  },
});
