import { $api } from "@/services/api";

export default function useFormHelpers(): any {
  function months(): any[] {
    return Array.from({ length: 12 }, (item, i) => {
      return {
        label: new Date(0, i).toLocaleString("en-US", { month: "long" }),
        value: i + 1, // Javascript starts months at 0
      };
    });
  }

  function years(future: boolean): any[] {
    const current = new Date().getFullYear();
    const years: any[] = [{ label: current.toString(), value: current }];

    if (future) {
      for (let i = 1; i <= 10; i++) {
        years.push({ label: (current + i).toString(), value: current + i });
      }
    } else {
      for (let i = 1; i <= 100; i++) {
        years.push({ label: (current - i).toString(), value: current - i });
      }
    }

    return years;
  }

  function days(year = 0, month = 0): any[] {
    const days = [];
    const dayCount = new Date(year, month, 0).getDate();

    for (let i = 1; i <= dayCount; i++) {
      days.push({ label: i.toString(), value: i });
    }

    return days;
  }

  function phoneTypes(): any[] {
    return [
      {
        label: "Mobile",
        value: "MOBILE",
      },
      {
        label: "Work",
        value: "WORK",
      },
      {
        label: "Home",
        value: "PERM",
      },
      {
        label: "Temporary",
        value: "TEMP",
      },
    ];
  }

  function calculateAge(dateStr: string) {
    const dob = new Date(dateStr);
    if (dateStr !== null) {
      const response = $api.applicant.calculateApplicantAge(dob);
      return response;
    } else {
      return 0;
    }
  }

  return { months, years, days, phoneTypes, calculateAge };
}
