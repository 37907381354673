import { Module } from "vuex";
import store from ".";

const modalModule: Module<any, any> = {
  namespaced: true,
  state: {
    active: false,
    addressModalActive: false as boolean,
    cancellationModalActive: false as boolean,
    currentAddressModalActive: false as boolean,
    servicerPayoffAddressModalActive: false as boolean,
    title: "",
    description: "",
    buttons: "",
    fullScreen: false,
    allowBackgroundScroll: true,
    type: "",
  },
  mutations: {
    closeAllModals(state: any) {
      state.active = false;
      state.addressModalActive = false;
      state.cancellationModalActive = false;
      state.currentAddressModalActive = false;
      state.servicerPayoffAddressModalActive = false;
    },
    openModal(state: any, options: any) {
      // close open modals
      state.active = false;

      // open this modal
      state.active = true;
      state.title = options.title || options;
      state.description = options.description || "";
      state.html = options.html || "";
      state.buttons = options.buttons || "";
      state.fullscreen = options.fullscreen || false;
      state.allowBackgroundScroll = options.allowBackgroundScroll || false;
    },
    closeModal(state: any) {
      state.active = false;
    },
    openAddressModal(state: any) {
      state.addressModalActive = true;
    },
    closeAddressModal(state: any) {
      state.addressModalActive = false;
    },
    openCurrentAddressModal(state: any) {
      state.currentAddressModalActive = true;
    },
    closeCurrentAddressModal(state: any) {
      state.currentAddressModalActive = false;
    },
    openCancellationModal(state: any) {
      state.cancellationModalActive = true;
    },
    closeCancellationModal(state: any) {
      state.cancellationModalActive = false;
    },
    openServicerPayoffAddressModal(state: any) {
      state.servicerPayoffAddressModalActive = true;
    },
    closeServicerPayoffAddressModal(state: any) {
      state.servicerPayoffAddressModalActive = false;
    },
  },
  actions: {
    openModal({ commit }, payload) {
      commit("openModal", payload);
    },
    closeModal({ commit }) {
      commit("closeModal");
    },
    openAddressModal({ commit }) {
      commit("openAddressModal");
    },
    closeAddressModal({ commit }) {
      commit("closeAddressModal");
      store.commit("forms/resetAddressForm");
    },
    openCurrentAddressModal({ commit }) {
      commit("openCurrentAddressModal");
    },
    closeCurrentAddressModal({ commit }) {
      commit("closeCurrentAddressModal");
      store.commit("forms/resetCurrentAddressForm");
    },
    openCancellationModal({ commit }) {
      commit("openCancellationModal");
    },
    closeCancellationModal({ commit }) {
      commit("closeCancellationModal");
    },
    openServicerPayoffAddressModal({ commit }) {
      commit("openServicerPayoffAddressModal");
    },
    closeServicerPayoffAddressModal({ commit }) {
      commit("closeServicerPayoffAddressModal");
      store.commit("forms/resetServicerPayoffAddressForm");
    },
  },
  getters: {
    currentAddressModalActive(state: any) {
      return state.addressModalActive;
    },
    addressModalActive(state: any) {
      return state.addressModalActive;
    },
    cancellationModalActive(state: any) {
      return state.cancellationModalActive;
    },
    servicerPayoffAddressModalActive(state: any) {
      return state.servicerPayoffAddressModalActive;
    },
  },
};
export default modalModule;
