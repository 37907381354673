import { Disbursement, EnrollmentPeriod, RequestFunds, School } from "@/models";
import { Module } from "vuex";
import { $api } from "@/services/api";
import { CreateDrawRequest } from "@/models/api";
import { DrawRequestSchool } from "@/models/school";
import { Option } from "@/models/form";
import store from "@/store";

const fundsModule: Module<RequestFunds, any> = {
  namespaced: true,
  state: {
    anticipatedGraduation: undefined,
    requestedAmount: "",
    disbursement: [],
    school: {
      schoolBranch: "",
      schoolCode: "",
      schoolId: 0,
      schoolLevelTypeId: 0,
      schoolName: "",
    } as DrawRequestSchool,
    fundingTerm: {
      description: "",
      id: 0,
      name: "",
      starDate: "",
      endDate: "",
      visibleStartDate: "",
      visibleEndDate: "",
    },
    enrollmentStatus: 0,
    errorMessage: "",
    states: [],
    schools: [],
    enrollmentPeriods: [],
    lastRequestedAmount: 0,
    loanAmount: 0,
    loanBoarded: undefined as undefined | boolean,
    draws: [],
    currentLoan: {},
  },
  getters: {
    DrawRequest: (state: RequestFunds): CreateDrawRequest => {
      const drawRequest: CreateDrawRequest = {
        loanId: state.currentLoan.id,
        schoolId: state.school.schoolId,
        schoolBranch: state.school.schoolBranch,
        schoolCode: state.school.schoolCode,
        schoolLevelTypeId: state.currentLoan.schoolLevelTypeId,
        enrollmentTermId: state.fundingTerm.id,
        requestedAmount: parseInt(state.requestedAmount),
        anticipatedGraduationDate: state.anticipatedGraduation ?? new Date(),
        enrollmentStatus: state.enrollmentStatus || 0,
      };
      return drawRequest;
    },
    DISBURSEMENTS: (state: RequestFunds): Array<Disbursement> => {
      return state.disbursement;
    },
    SchoolName: (state: RequestFunds): string => {
      return state.school?.schoolName ?? "";
    },
    GraduationMonth: (state: RequestFunds): number => {
      const monthIndex = state.anticipatedGraduation?.getMonth() ?? 0;
      if (state.anticipatedGraduation) {
        return monthIndex + 1;
      }
      return -1;
    },
    GraduationYear: (state: RequestFunds): number => {
      const year = state.anticipatedGraduation?.getFullYear() ?? 0;

      return year;
    },
    AcademicPeriodId: (state: RequestFunds): number => {
      return state.fundingTerm.id;
    },
    AcademicPeriod: (state: RequestFunds): string => {
      return state.fundingTerm?.description;
    },
    AmountRequested: (state: RequestFunds): string => {
      return state.requestedAmount;
    },
    GetErrorMessage: (state: RequestFunds): string => {
      return state.errorMessage;
    },
    ApprovedLoanAmount: (state: RequestFunds): number => {
      return state.loanAmount;
    },
    Draws: (state: RequestFunds): Array<any> => {
      return state.draws;
    },
    LastRequestedAmount: (state: RequestFunds): number | undefined => {
      return state.lastRequestedAmount;
    },
    Loan: (State: RequestFunds): any => {
      return State.currentLoan;
    },
    Schools: (State: RequestFunds): any => {
      return State.schools;
    },
    School: (State: RequestFunds): any => {
      return State.school;
    },
		EnrollmentStatusId: (state: RequestFunds): any => {
			return state.enrollmentStatus; 
		},
    EnrollmentStatusName: (state: RequestFunds): any => {
      return (
        store.state.enrollmentStatuses?.find((o: Option) => o.value === state.enrollmentStatus)
          ?.label || "Not Yet Selected"
      );
    },
  },
  mutations: {
    setErrorMessage(state: RequestFunds, message: string) {
      state.errorMessage = message;
    },
    setGraduationDate(state: RequestFunds, graduationDate: Date) {
      state.anticipatedGraduation = graduationDate;
    },
    setRequestAmount(state: RequestFunds, requestAmount: string) {
      state.requestedAmount = requestAmount;
    },
    resetModal(state: RequestFunds) {
      state.disbursement = [];
      state.errorMessage = "";
      state.requestedAmount = "";
    },
    resetAllStates(state: RequestFunds) {
      state.disbursement = [];
      state.errorMessage = "";
      state.requestedAmount = "";
      state.draws = [];
      state.loanAmount = 0;
      state.lastRequestedAmount = 0;
      state.currentLoan = {};
      state.anticipatedGraduation = undefined;
      state.school = {
        schoolBranch: "",
        schoolCode: "",
        schoolId: 0,
        schoolLevelTypeId: 0,
        schoolName: "",
      };
      state.fundingTerm = {
        description: "",
        id: 0,
        name: "",
        starDate: "",
        endDate: "",
        visibleStartDate: "",
        visibleEndDate: "",
      };
      state.enrollmentStatus = 0;
    },
    resetDisbursement(state: RequestFunds) {
      state.disbursement = [];
    },
    setApprovedLoanAmount(state: RequestFunds, loanAmount: number) {
      state.loanAmount = loanAmount;
    },
    setLastRequestedAmount(state: RequestFunds, lastRequestedAmount: number) {
      state.lastRequestedAmount;
    },
    setCurrentLoan(state: RequestFunds, currentLoan: any) {
      state.currentLoan = currentLoan;
    },
    setDisbursement(state: RequestFunds, disbursement: Disbursement) {
      state.disbursement.push(disbursement);
    },
    setFundingTerm(state: RequestFunds, fundingTerm: EnrollmentPeriod) {
      state.fundingTerm = fundingTerm;
    },
    setEnrollments(state: RequestFunds, enrollmentPeriods: []) {
      state.enrollmentPeriods = enrollmentPeriods;
    },
    setSchools(state: RequestFunds, schools: []) {
      state.schools = schools;
    },
    setStates(state: RequestFunds, states: []) {
      state.states = states;
    },
    setSchool(state: RequestFunds, school: School) {
      state.school = school;
    },
    setDrawModalSchool(state: RequestFunds, school: any) {
      state.school = {
        schoolBranch: school.schoolBranchDOEId,
        schoolCode: school.schoolDOEId,
        schoolId: school.id,
        schoolLevelTypeId: school.schoolTypeId,
        schoolName: school.schoolName,
      };
    },
    setLoanBoarded(state: RequestFunds, flag: boolean) {
      state.loanBoarded = flag;
    },
    setEnrollmentStatus(state: RequestFunds, enrollmentStatusId: number) {
      state.enrollmentStatus = enrollmentStatusId;
    },
  },
  actions: {
    async fetchEnrollmentTerms({ commit }) {
      try {
        const enrollments = await $api.lookups.getEnrollmentTermsLookups();

        if (enrollments) {
          commit("setEnrollments", enrollments);
        }
      } catch (error) {
        console.log(error);

        commit("setErrorMessage", error);
      }
    },
    async fetchEnrollmentPeriods({ commit }) {
      try {
        const enrollments = await $api.originator.getEnrollments();

        if (enrollments) {
          commit("setEnrollments", enrollments);
        }
      } catch (error) {
        console.log(error);

        commit("setErrorMessage", error);
      }
    },
    async fetchSchoolsAndStates({ commit }) {
      try {
        const [stateResult, schoolResult] = await Promise.all([
          $api.originator.getStates(),
          $api.originator.getSchools(
            store?.state?.appData?.application?.programTypeId,
            store?.state?.appData?.application?.productTypeId,
            store?.state?.appData?.application?.lenderId,
          ),
        ]);

        if (stateResult.error) {
          commit("setErrorMessage", stateResult.message);
          return;
        }

        if (schoolResult.error) {
          commit("setErrorMessage", schoolResult.message);
          return;
        }

        //If any error found we wont update the schools and states..
        if (schoolResult) {
          commit("setSchools", schoolResult);
        }

        if (stateResult.success) {
          commit("setStates", stateResult.results);
        }

        commit("setErrorMessage", "");
      } catch (error) {
        commit("setErrorMessage", error);
      }
    },
  },
};

export default fundsModule;
