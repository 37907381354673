<template>
  <Page>
    <div class="container">
      <div class="row content">
        <div class="col-12 col-lg-7 offset-lg-2 cuscd-item cuscd-add-side">
          <div class="cuscd-item-inner">
            <p class="cuscd-h2style">Authentication Error</p>
            <div class="cuscd-section-divide">
              <p class="cuscd-desc">
                There was an error logging you into the SLC Applicant Portal.
              </p>
              <br />
              <router-link
                to="/"
                class="btn btn-outline-secondary btn-sm cuscd-outline-cta"
              >
                Try Logging In Again
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
import Page from "../components/shared/layout/Page.vue";

export default defineComponent({
  components: { Page },
});
</script>
