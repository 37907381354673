
import { ImageConstants } from "@/config/constants";
import { defineComponent, computed } from "vue";
import { mapGetters, useStore } from "vuex";
import useHtmlUtilities from "@/use/htmlUtilities";
import useFormatter from "@/use/formatter";

export default defineComponent({
  props: [],
  name: "Hero",
  setup() {
    const store = useStore();
    const { decodeHTMLEncodedStr } = useHtmlUtilities();
    const { formatName } = useFormatter();
    const backgroundImage = computed(() => store.getters["backgroundImageUrl"]);
    const backgroundImageAltText = computed(() => store.getters["backgroundImageAltText"]);
    const backgroundImageClass = computed(() => store.getters["refiClassName"]);
		
    return {
      decodeHTMLEncodedStr,
      formatName,
      backgroundImage,
      backgroundImageAltText,
      backgroundImageClass,
    };
  },
  computed: {
    ...mapGetters("oidcStore", ["oidcIsAuthenticated", "oidcUser"]),
  },
});
