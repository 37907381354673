
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { $api } from "@/services/api";
import Modal from "@/components/shared/Modal.vue";
import SchoolInfo from "@/components/request-funds/SchoolInfo.vue";
import FundingTerm from "@/components/request-funds/FundingTerm.vue";
import AmountRequest from "@/components/request-funds/AmountRequest.vue";
import ReviewRequest from "@/components/request-funds/ReviewRequest.vue";
import Stepper from "@/components/shared/stepper/Stepper.vue";
import { mapGetters } from "vuex";
import { Loan } from "@/models";
import type { PropType } from "vue";
import DialogueModal from "../modals/DialogueModal.vue";

export default defineComponent({
  emits: ["complete", "previous", "getLoanDetails", "close"],
  setup(props, { emit }) {
    const store = useStore();
    const showDialogueModal = ref<boolean>(false);

    function openDialogueModal() {
      showDialogueModal.value = true;
    }

    function handleDialogueModal() {
      store.commit("fundsModule/resetAllStates");
      emit("getLoanDetails");
      emit("close");
    }
    return { store, showDialogueModal, openDialogueModal, handleDialogueModal };
  },
  props: {
    loan: {
      type: Object as PropType<Loan>,
      required: true,
      default: {} as any,
    },
  },
  components: {
    Modal,
    Stepper,
    SchoolInfo,
    FundingTerm,
    AmountRequest,
    ReviewRequest,
    DialogueModal,
  },
  data: () => ({
    loading: true,
    submitted: false,
    error: false,
    activeStep: 1,
    steps: [
      {
        title: "School Information",
      },
      {
        title: "Funding Term",
      },
      {
        title: "Amount Requested",
      },
    ],
    reviewConfirmation: false,
  }),
  computed: {
    ...mapGetters("oidcStore", ["oidcUser"]),
    ...mapGetters("fundsModule", ["Loan", "ApprovedLoanAmount", "LastRequestedAmount"]),
  },
  methods: {
    previous(event: any) {
      console.log("previous called");
      this.$emit("previous", event);
    },
    complete(event: any) {
      console.log("complete called");
      this.$emit("complete", event);
    },
    closeModal() {
      if (!this.submitted) {
        this.openDialogueModal();
      } else {
        this.store.commit("fundsModule/resetAllStates");
        this.$emit("close");
      }
    },
    amountRequestComplete(event: any) {
      this.activeStep = event.step;
    },
    fundingTermComplete(event: any) {
      this.activeStep = event.step;
    },
    schoolComplete(event: any) {
      this.activeStep = event.step;
    },
    submitComplete() {
      this.activeStep++;
      this.submitted = true;
      this.$emit("getLoanDetails");
    },
    goToReview() {
      this.activeStep = 4;
    },
    goBack() {
      if (this.activeStep === 4) {
        this.reviewConfirmation = false;
      }

      this.activeStep -= 1;
    },
    cancelButton() {
      // close the modal
      this.$emit("close");
    },
    closeDrawModal() {
      this.$emit("close", {
        refresh: true,
      });
    },
  },
});
