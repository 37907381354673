import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = { class: "cuscd-main-section cuscd-th-modern" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_Hero = _resolveComponent("Hero")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_Feedback = _resolveComponent("Feedback")!
  const _component_SupportModal = _resolveComponent("SupportModal")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_Loader, {
        key: 0,
        stretch: true,
        inverse: true
      }))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        style: _normalizeStyle(_ctx.themeStyle)
      }, [
        _createElementVNode("input", {
          type: "hidden",
          id: "lid",
          name: "lid",
          value: _ctx.lid
        }, null, 8, _hoisted_1),
        _createVNode(_component_Hero),
        _createVNode(_component_Header, { externalLinks: _ctx.externalLinks }, null, 8, ["externalLinks"]),
        _createElementVNode("section", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createVNode(_component_Footer, {
          externalLinks: _ctx.externalLinks,
          lid: _ctx.lenderData.lid
        }, null, 8, ["externalLinks", "lid"]),
        (_ctx.oidcIsAuthenticated)
          ? (_openBlock(), _createBlock(_component_Feedback, {
              key: 0,
              onShowSupportModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSupportModal = true))
            }))
          : _createCommentVNode("", true),
        (_ctx.showSupportModal)
          ? (_openBlock(), _createBlock(_component_SupportModal, {
              key: 1,
              id: "supportModal",
              onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSupportModal = false))
            }))
          : _createCommentVNode("", true)
      ], 4))
}