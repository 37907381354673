
import { defineComponent } from "vue";
import { mapState } from "vuex";
import useFormatter from "@/use/formatter";
import useValidation from "@/use/validation";
import BaseFormField from "@/components/shared/BaseFormField.vue";
import Icon from "@/components/shared/Icon.vue"
import Step from "@/components/shared/Step.vue";
import EstimateCalculator from "./EstimateCalculator.vue";
import Tile from "@/components/shared/Tile.vue";
import { FormSchema } from "@/models/form";
import NextButton from "@/components/shared/NextButton.vue";
import Modal from "@/components/shared/Modal.vue";

export default defineComponent({
  emits: ["previous", "close", "complete"],
  setup() {
    const { formatNumber } = useFormatter();
    const { validateForm, validateBorrowAmount } = useValidation();

    return { formatNumber, validateForm, validateBorrowAmount };
  },
  components: {
    Step,
    BaseFormField,
    Icon,
    EstimateCalculator,
    Tile,
    NextButton,
    Modal
  },
  props: {
    application: {
      type: Object,
      required: true,
    },
    term: {
      type: String,
      required: false,
    },
    initialDrawRequest: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isCurrent: {
      type: Boolean,
      required: false,
    },
    info: {
      type: Object,
      required: true,
    },
    updateBorrowAmount: {
      type: Boolean,
    },
  },
  data: () => ({
    currentSubStep: 2,
    amount: undefined as number | undefined,
    displayEstimateCalculator: false,
    isValid: false as boolean,
    allowZero: true,
    showAlertModal: false as boolean,
    schema: {
      amount: {
        name: "amount",
        type: "money",
        subtype: "initialDrawAmount",
        hideLabel: true,
        required: true,
        allowZero: true,
        allowDecimals: false,
        errorMessage: "An amount to borrow is required.",
        errors: [] as string[],
      },
    } as FormSchema,
    schoolInfo: {} as any,
  }),
  computed: {
    ...mapState(["appData", "lenderData"]),
    combinedTerms(): boolean {
      return (this.appData?.application?.schoolConfiguration?.academicTerm || "").includes("-")
    },
    disbursements(): any[] {
      const terms = this.term?.split("-") || [];
      return terms.map((term: string) => {
        return {
          term: term.trim(),
          amount: (this.amount || 0) / (terms || []).length,
        };
      });
    },
    terms(): string {
      const terms = this.term?.split("-") || [];
      let termString = "";
      for (let i = 0; i < terms.length; i++) {
        if (i === 0) {
          termString += terms[i];
        } else if (i > 0 && i < terms.length - 1) {
          termString += `, ${terms[i]}`;
        } else if (i === terms.length - 1) {
          termString += ` and ${terms[i]}`;
        }
      }
      return termString;
    },
    programTypeDisplayName(): string {
      return this.appData?.application?.programTypeId === 2 ? 'graduate': 'undergrad';
    }
  },
  watch: {
    amount(value) {
      this.validateBorrowAmount(this.schema.amount, this.amount, this.appData?.application?.loanAmount);
      if (value === "0") {
        return Number(0.0);
      } else if (value === "") {
        return undefined;
      } else {
        return value;
      }
    },
    updateBorrowAmount: {
      handler(newValue, oldValue) {
        console.log("test");

        if (newValue === true && oldValue === false) {
          this.goBackToPortal();
        }
      },
      deep: true,
    },
  },
  methods: {
    useThisAmount(event: any) {
      this.schema.amount.valid = true;
      this.schema.amount.errors = [];
      this.amount = event;
    },
    setReqAmount() {
      // this.amount = this.appData.application.initialDrawRequest.loanAmount || undefined;
      this.amount = this.initialDrawRequest?.amount || null;
    },
    updateApplication() {
      this.appData.bwrReqAmt = this.amount;
      // this.appData.application.loanAmount = this.amount;
    },
    goBack() {
      this.$emit("previous", true);
    },
    closeModal() {
      this.$emit("close", true);
    },
    toggleCalculator() {
      this.displayEstimateCalculator = !this.displayEstimateCalculator;
    },
    goBackToPortal() {
      this.$emit("complete", {
        amount: this.amount,
      });
    },
  },
  mounted() {
    this.setReqAmount();
  },
});
