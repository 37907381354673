<template>
  <div class="cuscd-item-inner cuscd-rounded error-tile">
    <h2>It's not you. It's us.</h2>
    <h4 class="cuscd-alert-text">
      Something went wrong on our end. Give us a couple of minutes and refresh
      your browser.
    </h4>
    <h4 class="cuscd-alert-text">
      If the problem persists, please contact
      <router-link to="/support"> Support </router-link>.
    </h4>
  </div>
</template>

<style scoped>
/*Error For both pages loans panel and documents share css*/
.error-tile {
  padding: 1.8rem 1.5rem;
  text-align: center;
}

.error-tile h4 {
  color: #000000;
}

.error-tile a {
  color: #000000;
  text-decoration: underline;
}
</style>
