import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f763868"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dot-floating" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loader-wrapper", {
      stretched: _ctx.stretch,
      inverse: _ctx.inverse,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 2))
}