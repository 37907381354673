
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";

export default defineComponent({
  props: [],
  computed: {
    ...mapState(["idleVue"]),
    ...mapGetters("oidcStore", ["oidcIsAuthenticated"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || "Applicant Portal";
      },
    },
  },
});
