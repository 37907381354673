import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4352a52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper-progressBar" }
const _hoisted_2 = { class: "progressBar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index + 1,
          class: _normalizeClass({
          active: index + 1 === _ctx.activeStep,
          complete: index + 1 < _ctx.activeStep
        }),
          style: _normalizeStyle({ zIndex: _ctx.stepCount - index })
        }, _toDisplayString(step.title), 7))
      }), 128))
    ])
  ]))
}