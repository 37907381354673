import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import store from "../store";
import Home from "../views/Home.vue";
import Applications from "../views/Applications.vue";
import Application from "../views/Application.vue";

import OidcCallback from "../views/OidcCallback.vue";
import OidcCallbackError from "../views/OidcCallbackError.vue";

import SchoolConfirmation from "../components/loan-configuration/SchoolConfirmation.vue";
import BorrowAmount from "../components/loan-configuration/BorrowAmount.vue";
import LoanConfirmation from "../components/loan-configuration/LoanConfirmation.vue";
import LoanOptions from "../components/loan-configuration/LoanOptions.vue";
import Documents from "@/views/Documents.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/applications",
    name: "Applications",
    component: Applications,
  },
  {
    path: "/applications/:referenceId",
    name: "Application",
    component: Application,
  },
  {
    path: "/applications/:referenceId/documents",
    name: "ApplicationDocuments",
    component: Documents,
  },
  {
    path: "/application/school-info",
    name: "SchoolConfirmation",
    component: SchoolConfirmation,
  },
  {
    path: "/application/borrow-amount",
    name: "BorrowAmount",
    component: BorrowAmount,
  },
  {
    path: "/application/loan-options",
    name: "LoanOptions",
    component: LoanOptions,
  },
  {
    path: "/application/loan-confirmation",
    name: "LoanConfirmation",
    component: LoanConfirmation,
  },
  {
    path: "/terms",
    name: "terms",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Terms.vue"),
    meta: {
      isPublic: true,
      title: "Terms of Use",
    },
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/PrivacyPolicy.vue"),
    meta: {
      isPublic: true,
      title: "Privacy Policy",
    },
  },
  {
    path: "/accessibility",
    name: "accessibility",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Accessibility.vue"),
    meta: {
      isPublic: true,
      title: "Accessibility",
    },
  },
  {
    path: "/support",
    name: "support",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/Support.vue"),
    meta: {
      isPublic: true,
      title: "Support",
    },
  },
  {
    path: "/loggedout",
    name: "loggedout",
    component: () =>
      import(/* webpackChunkName: "protected" */ "../views/LoggedOut.vue"),
    meta: {
      isPublic: true,
      title: "Logged Out",
    },
  },
  {
    path: "/oidc-callback", // Needs to match redirectUri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isPublic: true,
      title: "Redirecting...",
    },
  },
  {
    path: "/oidc-callback-error", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallbackError",
    component: OidcCallbackError,
    meta: {
      isPublic: true,
      title: "Error",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    const app = document.getElementById("app");
    if (app) {
      app.scrollIntoView();
    }
  },
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidcStore"));

export default router;
