
import { FieldSchema, FormField, Option } from "../../models/form";
import useValidation from "../../use/validation";
import useFormatter from "../../use/formatter";
import useHtmlUtilities from "../../use/htmlUtilities";
import { defineComponent, PropType, ref } from "vue";
import { mask } from "maska";
import Icon from "../shared/Icon.vue";
export default defineComponent({
  components: { Icon },
  setup() {
    const { validate, validateForm } = useValidation();
    const { formatPhoneNumber, formatZipCode } = useFormatter();
    const { isElementVisible } = useHtmlUtilities();
    const ssnInput = ref(null);
    const passwordInput = ref(null);
    const passwordConfirmationInput = ref(null);
    const autocompleteSearchInputError = ref("");
    const zipInput = ref(null);
    const radioInput = ref(null);
    let checked = false;
    return {
      validate,
      validateForm,
      formatZipCode,
      formatPhoneNumber,
      isElementVisible,
      ssnInput,
      zipInput,
      passwordInput,
      radioInput,
      passwordConfirmationInput,
      autocompleteSearchInputError,
      checked,
    };
  },
  props: {
    schema: {
      type: Object as PropType<FieldSchema>,
      required: true,
    },
    modelValue: {
      type: [String, Number, Boolean, Object],
      default: "",
    },
    options: {
      type: Array as PropType<Array<Option>>,
      default: [] as Array<Option>,
    },
    autocompleteOptions: {
      type: Array as PropType<Array<any>>,
      default: [] as Array<any>,
    },
    compare: {
      type: [String, Number, Boolean, Object],
      required: false,
    },
    validateOnKeyUp: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue"],
  data: () => ({
    field: {} as FormField,
    cleanedValue: undefined as any,
    autocompleteSearch: "",
    autoCompleteResults: [] as Option[],
    autoCompleteResultsError: "",
    autoCompleteIsOpen: false,
    autoCompleteArrowCounter: -1,
    showPassword: true,
    showConfirmationPassword: true,
    ignoreKeys: [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Enter",
      "Delete",
      "Shift",
      "CapsLock",
    ],
  }),
  computed: {
    hasHelpSlot(): boolean {
      return !!this.$slots.help;
    },

    autoCompleteResultsErrorMessage(): string {
      if (this.schema.autocompleteProperty === "schoolName") {
        return "Your school is not on our eligible schools list, and your school must be listed to be eligible for disbursements. Please check your spelling.";
      }
      return "Error";
    },
  },
  methods: {
    switchPasswordVisibility() {
      const passwordField = this.$refs["passwordInput"] as HTMLInputElement;
      // only allow toggle if password field is populated
      if (passwordField.value !== "") {
        this.showPassword = !this.showPassword;
        if (passwordField.getAttribute("type") === "password")
          passwordField.setAttribute("type", "text");
        else passwordField.setAttribute("type", "password");
      }
    },
    handlePaste(event: any) {
      let data = event.clipboardData.getData("text/plain");
      let matched = /^https?:\/\/.*$/.test(data);
      if (matched) {
        event.preventDefault();
      }
    },
    onAutocompleteChange(event: any) {
      if ((event.target.value === undefined || event.target.value === "") && this.modelValue) {
        this.$emit("update:modelValue", { id: null });
      }
      this.autoCompleteIsOpen = true;
      this.autocompleteSearch = event.target.value;

      this.autoCompleteResults = this.autocompleteOptions
        .filter((item) => {
          if (
            typeof this.modelValue === "object" &&
            this.schema.autocompleteProperty
          ) {
            return (
              item[this.schema.autocompleteProperty]
                .toLowerCase()
                .indexOf(
                  (this.autocompleteSearch || "").toString().toLowerCase()
                ) > -1
            );
          } else {
            return (
              item
                .toLowerCase()
                .indexOf(this.autocompleteSearch.toString().toLowerCase()) > -1
            );
          }
        })
        .slice(0, 10);

      // this.$emit("update:modelValue", event.target.value);
    },
    setAutocompleteValue(result: any) {
      this.$emit("update:modelValue", result);
      this.autocompleteSearch = this.schema.autocompleteProperty
        ? result[this.schema.autocompleteProperty]
        : result;
      this.autoCompleteIsOpen = false;
      this.autoCompleteArrowCounter = -1;
    },
    handleClickOutside(event: any) {
      if (!this.$el.contains(event.target)) {
        this.autoCompleteIsOpen = false;
      }
    },
    handleAutoCompleteBlur(schema: FormField, value: any, compare: any) {
      if (
        this.schema.autocompleteProperty &&
        this.autocompleteSearch !==
          (value[this.schema.autocompleteProperty || ""] || "").trim()
      ) {
        this.$emit("update:modelValue", { schoolId: null });
        value = undefined;
      }
      this.validate(schema, value, compare);
    },
    handleBlur(schema: FormField | FieldSchema, value: any, compare: any) {
      this.validate(schema, value, compare);
      if (schema.onBlur) {
        schema.onBlur(schema, value, compare);
      }
      this.formatValue();
    },
    onArrowDown() {
      // index appears to be offset by - 1
      if (this.autoCompleteArrowCounter < this.autoCompleteResults.length - 1) {
        this.autoCompleteArrowCounter = this.autoCompleteArrowCounter + 1;
        // Needs a split second to update the view
        setTimeout(() => {
          const results = this.$refs.autocompleteResults as any;
          const active = results.querySelector(".is-active");
          const isVisible = this.isElementVisible(active, results);
          if (!isVisible) {
            results.scrollTo(active.offsetBottom, active.offsetTop);
          }
        }, 50);
      }
    },
    onArrowUp() {
      if (this.autoCompleteArrowCounter > 0) {
        this.autoCompleteArrowCounter = this.autoCompleteArrowCounter - 1;
        // Needs a split second to update the view
        setTimeout(() => {
          const results = this.$refs.autocompleteResults as any;
          const active = results.querySelector(".is-active");
          const isVisible = this.isElementVisible(active, results);
          if (!isVisible) {
            results.scrollTo(active.offsetTop, active.offsetBottom);
          }
        }, 50);
      }
    },
    onEnter() {
      if (this.autoCompleteArrowCounter > -1) {
        const result: any =
          this.autoCompleteResults[this.autoCompleteArrowCounter];
        this.$emit("update:modelValue", result);
        this.autocompleteSearch = this.schema.autocompleteProperty
          ? result[this.schema.autocompleteProperty]
          : result;
        this.autoCompleteArrowCounter = -1;
        this.autoCompleteIsOpen = false;
      }
    },
    validateInput(event: any) {
      switch (this.schema.type) {
        case "phone":
          this.validatePhoneInput(event);
          break;
        case "ssn":
          this.validateSsnInput(event);
          break;
        case "zipcode":
          this.validateZipCodeInput(event);
          break;
        case "money":
          this.validateMoneyInputAmount(event);
          break;
        default:
          break;
      }
    },
    formatValue() {
      let cleanValue;
      switch (this.schema.type) {
        case "phone":
          cleanValue = `${this.modelValue}`.replace(/[^0-9]/g, "");
          if (cleanValue.trim().length === 10) {
            this.$emit("update:modelValue", this.formatPhoneNumber(cleanValue));
          }
          break;
        default:
          break;
      }
    },
    validateSsnInput(event: any) {
      if (this.ignoreKeys.indexOf(event.key) >= 0) {
        return;
      }

      const validDigit = /^[0-9]$/.test(event.key) || /^[-]$/.test(event.key);
      const cleanedSsn = `${this.modelValue}${event.key}`.split("-").join("");

      if ((!validDigit && event.key !== "Backspace") || cleanedSsn.length > 9) {
        event.preventDefault();
      }
    },
    validatePhoneInput(event: any) {
      if (this.ignoreKeys.indexOf(event.key) >= 0 || event.type === "keydown") {
        return;
      }

      const cleanedPhone = `${event.target.value}${event.key}`.replace(
        /[^0-9]/g,
        ""
      );
      const re = /^[0-9\b]+$/;
      const validChar =
        /^[0-9]$/.test(event.key) || /^[)( ._-]+$/g.test(event.key);
      if (
        event.key !== undefined &&
        (!validChar || event.key === "Backspace" || cleanedPhone.length > 10)
      ) {
        event.preventDefault();
      }
    },
    validateZipCodeInput(event: any) {
      if (this.ignoreKeys.indexOf(event.key) >= 0 || event.type === "keydown") {
        return;
      }
      // return /^\d*\-?\d*$/.test(value); // Allow digits and '-' only, using a RegExp

      const cleanedZip = `${this.modelValue}${event.key}`.split("-").join("");
      const validZipcode = /^\d{5}[\s*-\s*]?(?:\d{4})?$/m;
      const validChar = /^[0-9]$/.test(event.key) || /^[)(-]+$/g.test(event.key);
      if (
        event.key !== undefined &&
        (!validChar ||
          !validZipcode ||
          event.key === "Backspace" ||
          cleanedZip.length >= 10)
      ) {
        event.preventDefault();
      }
    },
    validateMoneyInputAmount(event: any) {
      if (this.ignoreKeys.indexOf(event.key) >= 0 || event.type === "keydown") {
        return;
      }
      const validChar = /^[0-9]$/.test(event.key);
      if (event.key !== undefined && (!validChar || event.key === "Backspace")) {
        event.preventDefault();
      }
    },
    handleOnBlur() {
      let stringValue = this.modelValue?.toString();
      switch (this.field.type) {
        case "ssn":
          // We only use the cleanedValue when the user has left the field
          this.cleanedValue = "";
          for (let i = 0; i < stringValue?.length; i++) {
            this.cleanedValue +=
              i <= 2 || i === 4 || i === 5 ? "*" : stringValue[i];
          }
          break;
        default:
          break;
      }
    },
  },
  beforeMount() {
    // Take in schema and create the form field
    if (this.schema) {
      this.field = {
        ...this.schema,
        touched: false,
        valid: false,
      } as FormField;
    }

    if (this.schema.type === "autocomplete") {
      this.autocompleteSearch = (this.modelValue as any)[
        this.schema.autocompleteProperty || ""
      ];
    }

    // If we don't do this it defaults booleans as true
    if (this.field.type === "checkbox" && this.modelValue === "") {
      this.$emit("update:modelValue", false);
    }
  },
  async mounted() {
    document.addEventListener("click", this.handleClickOutside);
    // // Handle default clicked radio
    // if (this.field.type === 'radio' && this.modelValue && this.radioInput) {
    //     this.$emit('update:modelValue', this.modelValue);
    // }
    // Handle cleanValues
    if (this.field.type === "ssn" && this.modelValue && this.ssnInput) {
      this.cleanedValue = mask(this.modelValue, "###-##-####", {
        "#": { pattern: /[0-9]/ },
      });
      this.$emit("update:modelValue", this.cleanedValue);
      (this.ssnInput as any)?.focus();
      // For whatever reason we need to surround the blur with setTimeout
      // with just a single millisecond to get Maska to hit it properly
      // to have the masking
      setTimeout(() => {
        (this.ssnInput as any)?.blur();
      }, 1);
    }
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
});
