import dayjs from "dayjs";
import moment from "moment-timezone";
import store from "@/store/index";

export default function useFormatter(): any {
  function formatNumber(number: number, decimals = 2): string {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    }).format(number);
  }

  function formatMoney(number: number, decimals = 2): string {
    const value = formatNumber(number, decimals);
    if (value.charAt(0) === "-") {
      return `-$${value.replace("-", "")}`;
    } else {
      return `$${value}`;
    }
  }
  function formattedDateFormat(dateStr: string, format: string): string {
    try {
      return dayjs(dateStr).format(format);
    } catch (ex) {
      console.log("Could not format date");
      return dateStr;
    }
  }

  function formatDateYYYYMMDD(dateStr: string, format = "YYYY/MM/DD"): string {
    try {
      return dayjs(dateStr).format(format);
    } catch (ex) {
      console.log("Could not format date");
      return dateStr;
    }
  }

  function formatDateMMDDYYY(dateStr: string, format = "MM/DD/YYYY"): string {
    try {
      return dayjs(dateStr).format(format);
    } catch (ex) {
      console.log("Could not format date");
      return dateStr;
    }
  }

  function formatDate(dateStr: string, format = "MMMM D, YYYY"): string {
    try {
      return dayjs(dateStr).format(format);
    } catch (ex) {
      console.log("Could not format date");
      return dateStr;
    }
  }

  function formatDateTime(dateStr: string) {
    try {
      if (dateStr) {
        return moment(`${dateStr}Z`)
        .tz(moment.tz.guess())
        .format('MMMM D, YYYY [at] h:mm A z')
      }
    } catch (ex) {
      console.log("Could not format date time");
      return dateStr;
    }
  }
  
  function formatPhoneNumber(phoneStr: string): string {
    const cleanedPhone = phoneStr.replace(/[^0-9]/g, "");

    return cleanedPhone.length === 10
      ? `(${cleanedPhone.substring(0, 3)})-${cleanedPhone.substring(
          3,
          6
        )}-${cleanedPhone.substring(6, 10)}`
      : phoneStr;
  }

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function formatBytesToKilobytes(bytes: number){
    if (bytes) {
      const kilobytes = bytes / 1024;
      console.log('formatBytesToKilobytes()', Number(kilobytes));

      return Number(kilobytes);
    }
  }

  function componentToHex(c: number) {
    const hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  function formatRgbToHex(r: number, g: number, b: number) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
  }

  function formatHexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  function formatGradDateSelectionValues(num: number) {
    //padTo2Digits
    return num.toString().padStart(2, "0");
  }

  function formatSchoolBranchDOEId(stringValue: string) {
    switch (stringValue?.length) {
      case 1:
        return stringValue.padStart(2, "0");
      default:
        return stringValue;
    }
  }

  function formatSchoolDOEId(stringValue: string) {
    switch (stringValue?.length) {
      case 4:
        return stringValue.padStart(6, "00");
      case 5:
        return stringValue.padStart(6, "0");
      default:
        return stringValue;
    }
  }

  function abstractDate(string: string){
    const regex = /[1-9][0-9][0-9]{2}([0][1-9]|[1][0-2])([1-2][0-9]|[0][1-9]|[3][0-1])/gm
    const result = string.match(regex) || "";
    if ((result || []).length >0 )
    {
      const dateStr = result[0];
      const year = parseInt(dateStr.substring(0,4));
      const month = parseInt(dateStr.substring(4,6));
      const day = parseInt(dateStr.substring(6,8));
      const date = new Date(year, month-1, day);
      return date
    } else {
      return string;
    }
  }

  function formatAddressLine2(address: any) {
    const unitType = store.state.lookupsModule.lookups.addressUnits.find((u: any) => u.id === address.unitType);
    console.log('unitType', unitType);
  }

  function formatName(input: string) {
    // this capitalizes each word of a string
    return input
      .split(" ")
      .map((word: string) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  }

  return {
    formatAddressLine2,
    formatDateTime,
    formatBytesToKilobytes,
    formatNumber,
    formatMoney,
    formatDate,
    formattedDateFormat,
    formatDateYYYYMMDD,
    formatDateMMDDYYY,
    formatPhoneNumber,
    formatBytes,
    formatHexToRgb,
    formatRgbToHex,
    formatGradDateSelectionValues,
    formatSchoolBranchDOEId,
    formatSchoolDOEId,
    abstractDate,
    formatName
  };
}
