
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
    },
    text: {
      type: String,
      default: "Next",
    },
  },
});
