import DOMPurify from "dompurify";

export default function useHtmlUtilities(): any {
  function isElementVisible(target: HTMLElement, container: HTMLElement) {
    container = container || document.body;
    const { top, bottom, height } = target.getBoundingClientRect();
    const holderRect = container.getBoundingClientRect();

    return top <= holderRect.top
      ? holderRect.top - top <= height
      : bottom - holderRect.bottom <= height;
  }

	function decodeHTMLEncodedStr(text: string) {
		const textArea = document.createElement('textarea');
		if (text) {
		  // Extra pre-processing to remove {{ }} from content prior to sanitizing
		  // potential limitation is if emails or passwords have curly braces...need to determine how we want to handle this -LS06302023
		  const newText = (text || "").replaceAll(/\{|\}/gi, '');

		  // remove amp;
		  const cleanedText = newText.replaceAll("amp;", '');

		  textArea.innerHTML = DOMPurify.sanitize(cleanedText);
		  return textArea.value;
		}
	  }

  return { isElementVisible, decodeHTMLEncodedStr };
}
