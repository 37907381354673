export enum Products {
  LOC = 1,
  REFI = 2,
}

export enum ApplicationStatuses {
  NOT_SUBMITTED = 1,
  EVALUATION = 2,
  SUBMITTED_AND_PROCESSING = 3,
  COMPLETE = 4,
  DECLINED = 5,
  CANCELLED = 6,
  SMR_DECLINED = 7,
  RIGHT_TO_CANCEL = 8,
}

export enum StipulationStatuses {
  OPEN = 1,
  COMPLETED = 2,
  CU_OVERRIDE = 3,
  CANCELLED = 4,
  PENDING = 5,
}

export enum PayoffVerificationStipulationTypes {
  ConfirmPayoffRequest = 62,
  LoanAccountStatement = 63,
  PayoffStatement = 65,
  LoanResponsibilityAffidavit = 67,
  ResponsibilityForUnderPayment = 68,
  ServicerPayoffAddress = 71,
}

export enum ProcessingCategories {
  Income = 1,
  Citizenship = 2,
  Membership = 3,
  Identity = 4,
  SpousalConsent = 5,
  FinalReview = 6,
  LoanConfiguration = 7,
  ESign = 8,
  Credit = 9,
  Misc = 10,
  PayoffVerification = 11,
  EligibilityVerification = 12,
  RateTerm = 13,
}

export enum SurveyQuestionTypes {
  TwoYearsIncome = 1,
  SelectedCreditRange = 2,
  MaritalStatus = 3,
  SMREstimateLoanAmount = 4,
  EmploymentStatus = 5,
  EmployerName = 6,
}

export enum IncomeSourceTypes {
  PAYSTUB = 1,
  SCHEDULE_C = 2,
  SOCIAL_SECURITY_BENEFITS = 3,
  RETIREMENT = 4,
  SCHEDULE_E = 5,
  OTHER_INCOME = 6,
}
