import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed391fc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps(_ctx.$attrs, {
    class: ["btn btn-primary", { loading: _ctx.loading }],
    disabled: _ctx.loading
  }), [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ], 16, _hoisted_1))
}