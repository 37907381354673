
import { defineComponent, ref, computed } from "vue";
import { useStore, mapGetters } from "vuex";
import useFormHelpers from "@/use/formHelpers";
import useValidation from "@/use/validation";
import { FormSchema, Option } from "@/models/form";
import Loader from "@/components/shared/Loader.vue";
import BaseFormField from "@/components/shared/BaseFormField.vue";

export default defineComponent({
  setup() {
    const { validate, validateForm } = useValidation();
    const { months, years } = useFormHelpers();
    const store = useStore();

    const enrollmentStatus = ref<undefined | number>(undefined);
    const enrollmentTerm = ref<undefined | number>(undefined);

    const selectedEnrollmentTerm = computed(() =>
      store.state?.fundsModule?.enrollmentPeriods?.find((p: any) => p.id == enrollmentTerm.value),
    );

    const enrollmentStatuses = computed(() => store.state.enrollmentStatuses);
    const enrollmentStatusOptions = computed(() => {
      return selectedEnrollmentTerm.value &&
        selectedEnrollmentTerm.value?.name?.toLowerCase()?.includes("summer")
        ? enrollmentStatuses.value
        : enrollmentStatuses.value?.filter((o: Option) => o.label !== "Less Than Half-Time");
    });

    return {
      store,
      validate,
      validateForm,
      months,
      years,
      enrollmentStatus,
      enrollmentTerm,
      enrollmentStatuses,
      enrollmentStatusOptions,
      selectedEnrollmentTerm,
    };
  },
  components: { Loader, BaseFormField },
  emits: ["complete", "close", "goback"],
  data: () => ({
    gettingData: true,
    error: false,
    anticipatedGraduation: {} as any,
    fundingSchema: {
      anticipatedGraduationMonth: {
        type: "select",
        label: "",
        required: true,
        errorMessage: "Graduation Month is required.",
        hideLabel: true,
      },
      anticipatedGraduationYear: {
        type: "select",
        label: "",
        required: true,
        errorMessage: "Graduation Year is required.",
        hideLabel: true,
      },
      enrollmentTerm: {
        type: "select",
        label: "Enrollment Term",
        errorMessage: "Enrollment Term is required.",
        hideLabel: true,
        required: true,
      },
      enrollmentStatus: {
        name: "enrollmentStatus",
        type: "select",
        label: "Enrollment Status",
        tooltipLocation: "bottom",
        errorMessage: "Enrollment Status is required.",
        required: true,
        hideLabel: true,
      },
    } as FormSchema,
  }),
  computed: {
    ...mapGetters("fundsModule", [
      "AcademicPeriodId",
      "GraduationMonth",
      "GraduationYear",
      "GetErrorMessage",
      "EnrollmentStatusId",
    ]),
    enrollmentTermOptions(): Option[] {
      return this.store.state.fundsModule.enrollmentPeriods.map((enrollment: any) => {
        return {
          label: enrollment.description,
          value: enrollment.id,
        };
      });
    },
  },
  methods: {
    confirmFundingTerm() {
      this.error = false;

      let fundingFormValid = this.validateForm(this.fundingSchema, {
        anticipatedGraduationMonth: this.anticipatedGraduation.month,
        anticipatedGraduationYear: this.anticipatedGraduation.year,
        enrollmentTerm: this.enrollmentTerm,
        enrollmentStatus: this.enrollmentStatus,
      });

      if (fundingFormValid) {
        // Default to the end of the month for now
        const graduationDate = new Date(
          this.anticipatedGraduation.year,
          this.anticipatedGraduation.month,
          0,
        );
        // const graduationDate = new Date(`${this.anticipatedGraduation.month}/28/${this.anticipatedGraduation.year}`);
        this.store.commit("fundsModule/setGraduationDate", graduationDate);

        const academicPeriod = this.store.state.fundsModule.enrollmentPeriods.find(
          (p: any) => p.id == this.enrollmentTerm,
        );

        if (academicPeriod) {
          const endDate = new Date(academicPeriod.endDate);
          const date1 = Date.UTC(
            graduationDate.getFullYear(),
            graduationDate.getMonth(),
            graduationDate.getDate(),
          );
          const date2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
          const isBeforeDate = date1 < date2;
          const difference = Math.floor((date1 - date2) / 1000 / 60 / 60 / 24);

          if (difference < 0 || isBeforeDate) {
            fundingFormValid = false;
            this.error = true;
            this.fundingSchema.enrollmentTerm.errorMessage = `Graduation Date must be after the selected Academic Period.`;
          }

          this.store.commit("fundsModule/setFundingTerm", academicPeriod);
        }

        // Handle enrollment status
        const selectedEnrollmentStatusId =
          typeof this.enrollmentStatus === "string"
            ? parseInt(this.enrollmentStatus)
            : this.enrollmentStatus;

        this.store.commit("fundsModule/setEnrollmentStatus", selectedEnrollmentStatusId);
      }

      if (fundingFormValid) {
        this.$emit("complete", { step: 3 });
      }
    },
  },
  async mounted() {
    this.gettingData = true;
    if (this.store.state.fundsModule.enrollmentPeriods.length <= 0) {
      await this.store.dispatch("fundsModule/fetchEnrollmentPeriods");
    }

    //Check if previously assigned an academic period..
    this.enrollmentTerm = this.AcademicPeriodId > 0 ? this.AcademicPeriodId : undefined;
    this.anticipatedGraduation.month =
      this.GraduationMonth == -1 ? undefined : this.GraduationMonth;
    this.anticipatedGraduation.year = this.GraduationYear > 0 ? this.GraduationYear : undefined;
    this.enrollmentStatus = this.EnrollmentStatusId > 0 ? this.EnrollmentStatusId : undefined;
    this.gettingData = false;
  },
});
