
import useFormatter from "@/use/formatter";
import { defineComponent } from "vue";
import { mapState } from "vuex";
import usePaymentOptions from "../../use/paymentOptions";
import Step from "../shared/Step.vue";

export default defineComponent({
  setup() {
    const { formatMoney, formatDate, formatNumber } = useFormatter();
    const { getPaymentOptionHeader } = usePaymentOptions();
    return { formatMoney, formatDate, getPaymentOptionHeader, formatNumber };
  },
  props: {
    application: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isCurrent: {
      type: Boolean,
      required: false,
    },
    loanConfig: {
      type: Object,
    },
    info: {
      type: Object,
    },
    isCurrentSubStep: {
      type: Boolean,
      default: false,
    },
    schoolSelected: {
      type: Boolean,
    },
  },
  components: { Step },
  data: () => ({
    loanInfo: {} as any,
    currentSubStep: 4,
    schoolInfo: {} as any,
  }),
  computed: {
    ...mapState(["appData", "schools", "lenderData"]),
    school(): any {
      return this.appData?.application?.schoolConfiguration?.schoolName;
    },
    combinedTerms(): boolean {
      return (this.appData?.application?.schoolConfiguration?.academicTerm || "").includes("-")
    },
    currentYear(): any {
      return new Date().getFullYear();
    },
    disbursements(): any[] {
      const terms =
        (
          this.appData.application?.schoolConfiguration?.academicTerm || ""
        ).split("-") || [];
      return terms.map((term: string) => {
        return {
          term: term.trim(),
          amount:
            (isNaN(this.appData.application?.loanAmount)
              ? this.appData.application?.loanAmount
              : 0) / (terms || []).length,
        };
      });
    },
  },
  watch: {
    isCurrent() {
      this.setLoanInfo();
    },
  },
  methods: {
    rateTypeHeader(type: number): string {
      switch (type) {
        case 1:
          return "Fixed Interest Rate";
        case 2:
          return "Variable Interest Rate";
        default:
          return "";
      }
    },

    async setLoanInfo() {
      if (this.isCurrent) {
        const gradDate = new Date(this.info?.graduationDate);
        console.log("this.info on setLoanInfo()", this.info);
        this.loanInfo = {
          schoolName: this.info?.school?.schoolName,
          academicTerm: this.info?.academicTerm,
          gradDate: this.info?.graduationDate
            ? `${gradDate.toLocaleString("default", {
                month: "long",
              })} ${gradDate.getFullYear()}`
            : "Not Yet Selected",
          loanAmount: this.info?.loanAmount,
          loanAmountPerTerm:
            this.combinedTerms
              ? this.info?.loanAmount / 2
              : this.info?.loanAmount,
          rateTypeId: this.info?.rateTypeId,
          repaymentTypeId: this.info?.repaymentTypeId,
          monthlyInSchoolPayment:
            this.info?.monthlyInSchoolPayment === 0
              ? this.info?.monthlyInSchoolPayment.toLocaleString()
              : this.info?.monthlyInSchoolPayment,
          monthlyPostSchoolPayment: this.info?.monthlyPostSchoolPayment,
        };
      }
    },

    confirmLoan() {
      this.$emit("complete", this.loanInfo);

      window.location.href = `${(
        this.lenderData.applicantPortalUrl || ""
      ).trim()}${
        this.lenderData.lid > 0 ? `?LID=${this.lenderData.lid}`.trim() : ""
      }`;
    },
    editInformation(step: number) {
      this.$emit("edit", step);
    },
  },
  mounted() {
    if (this.isCurrent) {
      this.setLoanInfo();
    }
  },
});
