import store from "../store";
import router from "../router";
import { $api } from "../services/api";

export default function useSignOut() {
  async function signOut() {
    const oidcUser = store.getters["oidcStore/oidcUser"];
    const lenderData = store.state["lenderData"];

    if (oidcUser) {
      await $api.account.signout(oidcUser.sub);
    }
    store.dispatch("oidcStore/signOutOidcSilent");
    store
      .dispatch("oidcStore/removeOidcUser")
      .then(() => {
        localStorage.removeItem("slc-lid");
        if (
          lenderData &&
          lenderData.redirectOnLogout &&
          lenderData.postLogoutRedirectUri !== ""
        ) {
          window.location.href = lenderData.postLogoutRedirectUri;
        } else {
          router.push("/loggedout");
        }
      })
      .catch((err) => {
        console.log("REMOVE OIDC USER ERROR", err);
      });
  }

  return { signOut };
}
